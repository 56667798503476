import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import {
  Alert,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { formatInTimeZone } from "date-fns-tz";
import DownloadIcon from "@mui/icons-material/Download";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import copy from "copy-to-clipboard";
import { get, off, onValue, ref, set } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AWSACCESSKEY,
  secretAccessKey: process.env.REACT_APP_AWSSECRETKEY,
});

const s3 = new AWS.S3();

const FileUploadViewer = () => {
  const [files, setFiles] = useState([]);
  const [filterQuery, setFilterQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const [sendMessageSuccess, setSendMessageSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { currentUser, logout } = useAuth();
  const [filesInfo, setFilesInfo] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageHovered, setImageHovered] = useState("");

  const handlePopoverOpen = (event, image) => {
    if (isImage(image)) {
      const baseUrl = "https://s3.amazonaws.com/upload.teldio.com/";
      const imageURL = `${baseUrl}${image}`;
      setImageHovered(imageURL);
      setAnchorEl(event.currentTarget);
    }
  };

  function openImage(image) {
    if (isImage(image)) {
      const baseUrl = "https://s3.amazonaws.com/upload.teldio.com/";
      const imageURL = `${baseUrl}${image}`;
      window.open(imageURL, "_blank");
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isImage = (fileName) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    console.log(fileExtension);
    return imageExtensions.includes(fileExtension);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const db = getDB();

    // Create a reference to the specific path in the Firebase Realtime Database
    const recentRef = ref(db, `/files/`);

    // Listen for changes at the specified reference
    const unsubscribe = onValue(
      recentRef,
      (snapshot) => {
        if (snapshot.exists()) {
          console.log("Updated data:", snapshot.val());
          setFilesInfo(Object.values(snapshot.val()));
        } else {
          console.log("No data available");
        }
      },
      (error) => {
        console.error("Failed to read data:", error);
      }
    );

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => off(recentRef, "value", unsubscribe);
  }, [currentUser.uid]); // Dependencies for useEffect, ensures listener updates if db or userId changes

  const handleCopy = (key) => {
    const baseUrl = "https://s3.amazonaws.com/upload.teldio.com/";
    const downloadUrl = `${baseUrl}${key}`;

    copy(downloadUrl) ? setCopySuccess(true) : setCopyError(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySuccess(false);
    setCopyError(false);
    setSendMessageSuccess(false);
  };

  const sendToGoogleChat = (link) => {
    const webhookUrl = `https://chat.googleapis.com/v1/spaces/AAAAi39XrGQ/messages?key=${process.env.REACT_APP_GKEY}&token=${process.env.REACT_APP_GTOKEN}`;
    const message = {
      text: `Download link: ${link}`,
    };

    fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Message sent successfully");
          setSendMessageSuccess(true);
        } else {
          console.error("Failed to send message:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  const handleSendtoBacon = (key) => {
    const baseUrl = "https://s3.amazonaws.com/upload.teldio.com/";
    const downloadUrl = `${baseUrl}${key}`;
    sendToGoogleChat(downloadUrl);
  };

  const handleDownload = (key) => {
    const baseUrl = "https://s3.amazonaws.com/upload.teldio.com/";
    const downloadUrl = `${baseUrl}${key}`;

    // Fetch the file data
    fetch(downloadUrl)
      .then((response) => response.blob()) // Convert the response to a blob
      .then((blob) => {
        // Create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element for downloading
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = key.replace("fileupload/", ""); // Set the filename

        // Append the link, trigger it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the blob URL after the download
        setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function getFiletype(inputString) {
    const lastIndex = inputString.lastIndexOf(".");
    if (lastIndex === -1) return "";
    return inputString.substring(lastIndex + 1);
  }
  const getFilteredFiles = () => {
    let filteredFiles = files.filter((file) => {
      // Extract file key, uploader, and description for the filter check
      const fileKey = file.Key.toLowerCase();
      const uploader =
        filesInfo
          ?.find((info) => info.name === file.Key.replace("fileupload/", ""))
          ?.customer?.toLowerCase() || "";
      const description =
        filesInfo
          ?.find((info) => info.name === file.Key.replace("fileupload/", ""))
          ?.description?.toLowerCase() || "";

      // Check if any of the fields include the filter query
      return (
        (fileKey.includes(filterQuery.toLowerCase()) ||
          uploader.includes(filterQuery.toLowerCase()) ||
          description.includes(filterQuery.toLowerCase())) &&
        file.Size > 0
      );
    });

    if (filteredFiles.length === 0) {
      return [];
    }
    // Sort by LastModified descending
    filteredFiles.sort(
      (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
    );

    const startIndex = (currentPage - 1) * 25;
    const endIndex = startIndex + 25;
    return filteredFiles.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(
    files.filter(
      (file) =>
        file.Key.toLowerCase().includes(filterQuery.toLowerCase()) &&
        file.Size > 0
    ).length / 25
  );

  useEffect(() => {
    const fetchFiles = async () => {
      const params = {
        Bucket: "upload.teldio.com",
        Prefix: "fileupload/",
      };

      try {
        const data = await s3.listObjectsV2(params).promise();
        setFiles(data.Contents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, []);

  return (
    <div style={{ width: "90%", marginLeft: "5%" }}>
      <div style={{ marginTop: "15px", paddingBottom: "50px" }}>
        <TextField
          label="Search Files"
          variant="outlined"
          value={filterQuery}
          onChange={(e) => setFilterQuery(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <SearchIcon
                position="start"
                sx={{ p: 0.25, color: "#A9A9A9", ml: -0.5, mr: 1 }}
              />
            ),
          }}
          sx={{
            backgroundColor: "white",
            borderRadius: "0px",
            border: "0px",
            marginBottom: "10px",
          }}
        />
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#A0AD39", marginTop: "30px" }} />
          </div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>File</TableCell>
                    <TableCell>Uploader</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Last Modified</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {getFilteredFiles().length > 0 && (
                  <TableBody>
                    {getFilteredFiles().map((row) => (
                      <TableRow key={row.ETag}>
                        <TableCell
                          sx={{ width: "50%", cursor: "pointer" }}
                          onClick={() => {
                            openImage(row.Key);
                          }}
                          onMouseEnter={(e) => {
                            handlePopoverOpen(e, row.Key);
                          }}
                          onMouseLeave={handlePopoverClose}
                        >
                          {row.Key.replace("fileupload/", "")}
                        </TableCell>
                        <TableCell sx={{ width: "10%" }}>
                          {
                            filesInfo.find(
                              (item) =>
                                item.name === row.Key.replace("fileupload/", "")
                            )?.customer
                          }
                        </TableCell>
                        <TableCell sx={{ width: "10%" }}>
                          {
                            filesInfo.find(
                              (item) =>
                                item.name === row.Key.replace("fileupload/", "")
                            )?.description
                          }
                        </TableCell>
                        <TableCell>
                          {formatInTimeZone(
                            new Date(row.LastModified),
                            "America/New_York",
                            "yyyy-MM-dd HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>{getFiletype(row.Key)}</TableCell>
                        <TableCell>{formatBytes(row.Size)}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <IconButton
                              aria-label="Download"
                              size="small"
                              onClick={() => {
                                handleDownload(row.Key);
                              }}
                            >
                              <Tooltip title="Download File">
                                <DownloadIcon fontSize="inherit" />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              aria-label="Link"
                              size="small"
                              onClick={() => {
                                handleCopy(row.Key);
                              }}
                            >
                              <Tooltip title="Copy File Link">
                                <InsertLinkIcon fontSize="inherit" />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              aria-label="baconChat"
                              size="small"
                              onClick={() => {
                                handleSendtoBacon(row.Key);
                              }}
                            >
                              <Tooltip title="Send to Bacon Chat">🥓 </Tooltip>
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </>
        )}
        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Link copied to clipboard!
          </Alert>
        </Snackbar>
        <Snackbar
          open={copyError}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Failed to copy link to clipboard. Copy manually.
          </Alert>
        </Snackbar>
        <Snackbar
          open={sendMessageSuccess}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Message sent successfully!
          </Alert>
        </Snackbar>
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
        >
          <img
            src={imageHovered}
            alt="Preview"
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
            }}
          />
        </Popover>
      </div>
    </div>
  );
};

export default FileUploadViewer;
