import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
  Stack,
  FormControlLabel,
  Modal,
  List,
  ListItem,
  Divider,
  Tooltip,
  Avatar,
  ListItemButton,
  Snackbar,
  Popover,
  MenuItem,
  Menu,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import {
  Label as LabelIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ref, update, remove, onValue, get, push } from "firebase/database";
import Checklist from "./TaskModal/Checklist";
import Attachments from "./TaskModal/Attachments";
import Comments from "./TaskModal/Comments";
import {
  SquaredButton,
  SquaredButtonBase,
  SquaredButtonGray,
  SquaredButtonGreen,
  TaskModalButton,
  TaskModalButtonBase,
  TeldioCheckbox,
  TeldioTextField,
} from "../../MUI_Components/Components";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { convertToArray, getTextColor } from "../../Helper/utils";
import GroupIcon from "@mui/icons-material/Group";
import ShareIcon from "@mui/icons-material/Share";
import ArchiveIcon from "@mui/icons-material/Archive";
import Description from "./TaskModal/Description";
import Cover from "./TaskModal/Cover";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Links from "./TaskModal/Links";
import LinkIcon from "@mui/icons-material/Link";
import PollPopover from "./TaskModal/PollPopover";
import PollIcon from "@mui/icons-material/Poll";
import Poll from "./TaskModal/Poll";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useSettings } from "../../Contexts/SettingsContext";
import Images from "./TaskModal/Images";
import ImageIcon from "@mui/icons-material/Image";
import RichDescription from "./TaskModal/RichDescription";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import History from "./TaskModal/History";
import MovePopover from "./TaskModal/MovePopover";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { v4 as uuidv4 } from "uuid";
import heic2any from "heic2any";
import RichComments from "./TaskModal/RichComments";
import { useThemeContext } from "../../Contexts/ThemeDetailsContext";

const TaskModal = ({
  taskId,
  boardId,
  columnId,
  onClose,
  columnName,
  boardOwner,
  collaborators,
  handleAssignMember,
  handleUnassignMember,
  assignedMembers,
  logAudit,
  logToColumn,
  reverseComments,
  setReverseComments,
  boardTitle,
  taskPath,
  boardPath,
  isLinked,
  realTaskPath,
  ownBoards,
  collaboratedBoards,
  handleClearNotifications,
}) => {
  const [task, setTask] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [comments, setComments] = useState([]);
  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState("");
  const [labels, setLabels] = useState([]);
  const [userDraggedFile, setUserDraggedFile] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [labelsModalOpen, setLabelsModalOpen] = useState(false);
  const [assignedModalOpen, setAssignedModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { currentUser } = useAuth();
  const [openCopyLink, setOpenCopyLink] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moveAnchorEl, setMoveAnchorEl] = useState(null);
  const [pollAnchorEl, setPollAnchorEl] = useState(null);
  const [dueDateAnchorEl, setDueDateAnchorEl] = useState(null);
  const [isWatching, setIsWatching] = useState(false);
  const { settings } = useSettings();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editingDescription, setEditingDescription] = useState(false);
  const [isSelectingText, setIsSelectingText] = useState(false);
  const [uploading, setUploading] = useState(false);
  const storage = getStorage();
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const themeDetails = useThemeContext();

  useEffect(() => {
    handleClearNotifications();
  }, [handleClearNotifications]);

  function mergeAndSort(comments = [], history = []) {
    // Add type field to comments and convert createdAt to timestamp format for consistent sorting
    const formattedComments = comments
      .filter((comment) => comment.createdAt) // Ensure the comment has a createdAt field
      .map((comment) => ({
        ...comment,
        type: "comment",
        timestamp: new Date(comment.createdAt).getTime(), // Convert to timestamp for easier sorting
      }));

    // Add type field to history
    const formattedHistory = history
      .filter((item) => item.timestamp) // Ensure history items have a valid timestamp
      .map((item) => ({
        ...item,
        type: "history",
      }));

    // Merge the two arrays
    const mergedArray = [...formattedComments, ...formattedHistory];

    // Sort the merged array based on the timestamp
    mergedArray.sort((a, b) => b.timestamp - a.timestamp);

    return mergedArray;
  }

  // Handle opening the popover and setting the anchor element
  const handleOpenDueDatePopover = (event) => {
    setDueDateAnchorEl(event.currentTarget); // Store the button as the anchor element
    setOpen(true); // Open the popover
  };

  // Handle closing the popover
  const handleCloseDueDatePopover = () => {
    setDueDateAnchorEl(null);
    setOpen(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSaveDueDate = () => {
    if (selectedDate) {
      // Save to the database
      const taskRef = ref(db, taskPath);
      update(taskRef, { dueDate: selectedDate.toISOString() });
    }
    handleCloseDueDatePopover();
  };

  const handleOpenCoverPopover = (event) => {
    console.log("first", event);
    setAnchorEl(event.currentTarget);
  };

  const handleOpenMovePopover = (event) => {
    console.log("first", event);
    setMoveAnchorEl(event.currentTarget);
  };

  const handleMouseDown = (e) => {
    if (window.getSelection()?.toString()) {
      setIsSelectingText(true); // Text selection started
    }
  };

  const handleMouseUp = (e) => {
    setTimeout(() => setIsSelectingText(false), 100); // Give some time for the selection to finish
  };

  const handleRemoveDueDate = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { dueDate: null }).then(() => {
      setSelectedDate(null);
      handleCloseDueDatePopover(); // Close the popover after removal
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleOpenPollPopover = (event) => {
    setPollAnchorEl(event.currentTarget);
  };

  const handleCloseCoverPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleCloseMovePopover = (e) => {
    try {
      e.stopPropagation();
    } catch (error) {}
    setMoveAnchorEl(null);
  };
  const handleClosePollPopover = (e) => {
    e.stopPropagation();
    setPollAnchorEl(null);
  };

  function debounce(func, delay) {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const handleFileUpload = async (file) => {
    setUploading(true);

    if (!file?.type.startsWith("image/")) {
      alert("Only images are allowed");
      setUploading(false);
      return;
    }

    let fileToUpload = file;

    // Check if it's a .heic file and convert it before uploading
    if (file.type === "image/heic" || file.name.endsWith(".heic")) {
      try {
        const heicConversionResult = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        const jpegBlob = new Blob([heicConversionResult], {
          type: "image/jpeg",
        });

        // Replace the original .heic file with the converted JPEG file
        fileToUpload = new File([jpegBlob], `${file.name.split(".")[0]}.jpeg`, {
          type: "image/jpeg",
        });
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        alert("Failed to convert HEIC image. Please try another file.");
        setUploading(false);
        return;
      }
    }

    // Sanitize the file name by replacing Firebase disallowed characters
    const sanitizedFileName = fileToUpload.name.replace(/[.$\/\[\]#]/g, "-");

    // Generate a unique identifier (UUID or timestamp) to add to the file name
    const uniqueIdentifier = uuidv4(); // Alternatively, you can use Date.now()
    const uniqueFileName = `${sanitizedFileName}-${uniqueIdentifier}`;

    const storageReference = storageRef(
      storage,
      `images/${taskPath}/${uniqueFileName}`
    );

    uploadBytes(storageReference, fileToUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const newImage = {
          url,
          name: uniqueFileName,
          uploadedAt: new Date().toISOString(),
        };

        // Update state using the functional form to ensure you have the latest state
        setImages((prevImages) => {
          const updatedImages = [...prevImages, newImage];

          // Save attachments to database
          const taskRef = ref(db, `${taskPath}`);
          update(taskRef, { images: updatedImages });

          return updatedImages;
        });

        setUploading(false);
      });
    });

    logAudit(
      "Image added",
      `${uniqueFileName} has been added`,
      `added an image`
    );
  };

  useEffect(() => {
    function handlePaste(e) {
      const items = e.clipboardData?.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.kind === "file" && item.type.startsWith("image/")) {
            handleShowImages();
            const file = item.getAsFile();
            if (file) {
              e.preventDefault();
              e.stopPropagation();
              handleFileUpload(file); // Call your file upload function
              return;
            }
          }
        }
      }
    }

    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    function debounce(func, delay) {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    }

    const checkAssignedStatus = debounce(() => {
      const isAssigned = assignedMembers?.some(
        (assigned) => assigned.uid === currentUser.uid
      );
      const currentUserMember = convertToArray(collaborators).find(
        (collaborator) => collaborator.uid === currentUser.uid
      );

      if (!isAssigned) {
        handleAssignMember(currentUserMember);
        console.log("assigned", currentUserMember);
      } else {
        handleUnassignMember(currentUserMember);
        console.log("unassigned", currentUserMember);
      }
    }, 300);

    let pressed = false;

    function handleKeyDown(e) {
      const activeElement = document.activeElement;
      const isInteractiveElement =
        activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.getAttribute("contenteditable") === "true";

      if (
        e.keyCode === 32 &&
        !pressed &&
        !editingDescription &&
        !isInteractiveElement
      ) {
        // 32 is the spacebar key
        e.preventDefault();
        e.stopPropagation();
        pressed = true; // Mark the spacebar as pressed
        checkAssignedStatus();
        setTimeout(() => (pressed = false), 300); // Reset after 300ms
      }
      if (
        e.keyCode === 27 &&
        !pressed &&
        !editingDescription &&
        !isInteractiveElement
      ) {
        // 27 is the esc key
        e.preventDefault();
        e.stopPropagation();
        handleCloseModal();
        setTimeout(() => (pressed = false), 300); // Reset after 300ms
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [collaborators, assignedMembers, currentUser, editingDescription]);

  const openCoverPopover = Boolean(anchorEl);
  const openPollPopover = Boolean(pollAnchorEl);
  const openMovePopover = Boolean(moveAnchorEl);
  const openDueDatePopover = Boolean(dueDateAnchorEl);
  const id = openCoverPopover ? "simple-popover" : undefined;
  const pollID = openPollPopover ? "simple-popover" : undefined;
  const moveID = openMovePopover ? "simple-popover" : undefined;
  const dueDateID = openDueDatePopover ? "simple-popover" : undefined;

  const db = getDB();

  useEffect(() => {
    const labelsRef = ref(db, `${boardPath}/labels`);
    onValue(labelsRef, (snapshot) => {
      const labelsData = snapshot.val() || [];
      setLabels(labelsData);
    });
  }, [boardId, currentUser.uid, db, boardOwner, boardPath]);

  useEffect(() => {
    const watchersRef = ref(
      db,
      `${boardPath}/columns/${columnId}/tasks/${taskId}/watchers`
    );
    onValue(watchersRef, (snapshot) => {
      const watchers = snapshot.val() || {};
      setIsWatching(!!watchers[currentUser.uid]);
    });
  }, [taskId, columnId, boardPath, db, currentUser.uid]);

  const toggleWatchStatus = async () => {
    let watchersRef = ref(
      db,
      `${boardPath}/columns/${columnId}/tasks/${taskId}/watchers`
    );

    if (isWatching) {
      // Remove current user from watchers
      watchersRef = ref(
        db,
        `${boardPath}/columns/${columnId}/tasks/${taskId}/watchers/${currentUser.uid}`
      );
      await remove(watchersRef);
    } else {
      // Add current user to watchers
      await update(watchersRef, {
        [currentUser.uid]: true,
      });
    }

    setIsWatching(!isWatching);
  };

  function handleCloseCopyLink() {
    setOpenCopyLink(false);
  }

  function handleCopyCard() {
    const ownerUid = boardOwner === "own" ? currentUser.uid : boardOwner;
    const data = [ownerUid, boardId, columnId, taskId];
    navigator.clipboard
      .writeText(data)
      .then(() => {
        setOpenCopyLink("Copied to clipboard");
      })
      .catch((err) => {
        setOpenCopyLink("Failed to copy card");
      });
  }
  function handleOpenCopyLink() {
    setOpenCopyLink(false);
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setOpenCopyLink("Copied to clipboard");
      })
      .catch((err) => {
        setOpenCopyLink("Failed to copy card URL");
      });
  }

  useEffect(() => {
    const taskRef = ref(db, taskPath);
    onValue(taskRef, (snapshot) => {
      const taskData = snapshot.val();
      if (taskData) {
        setTask(taskData);
        setAttachments(taskData.attachments || []);
        setImages(taskData.images || []);
        setChecklists(taskData.checklists || []);
        // const commentsReverseOrder = settings?.reverseComments;
        // if (commentsReverseOrder) {
        //   setComments(taskData?.comments?.reverse() || []);
        // } else {
        //   setComments(taskData?.comments || []);
        // }
        const commentsReverseOrder = !settings?.reverseComments;

        const sortedComments = taskData?.comments?.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return commentsReverseOrder ? dateB - dateA : dateA - dateB;
        });

        setComments(sortedComments || []);
        setTitle(taskData.title);
        setSelectedLabels(taskData.labels || []);
      }
    });
  }, [taskId, columnId, boardPath, db, reverseComments, taskPath]);

  const handleSaveTitle = () => {
    if (title.length > 100 || title?.trim().length === 0) {
      return;
    }
    const taskRef = ref(db, taskPath);
    update(taskRef, { title }).then(() => {
      setEditingTitle(false);
    });
    logAudit(
      "Card Title Changed",
      `Card title changed from ${task?.title} to ${title}`,
      `changed title from <bold>${task?.title}</bold> to <bold>${title}</bold>`
    );
  };

  const handleSaveCover = (cover) => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { cover: cover });
  };

  const handleMoveTask = (task) => {
    console.log("task", task);
  };

  const handleArchiveCard = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { archived: true });
    logAudit(
      "Card Archived",
      `Card ${task?.title} has been archived`,
      "archived this card"
    );
  };

  const handleShowChecklist = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { checklistShow: true });
  };

  const handleHideChecklist = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { checklistShow: false });
  };

  const handleShowLinks = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { linksShow: true });
  };

  const handleHideLinks = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { linksShow: false });
  };

  const handleShowAttachments = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { attachmentsShow: true });
  };

  const handleHideAttachments = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { attachmentsShow: false });
  };

  const handleShowImages = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { imagesShow: true });
  };

  const handleHideImages = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { imagesShow: false });
  };

  const handleUnArchiveCard = () => {
    const taskRef = ref(db, taskPath);
    update(taskRef, { archived: false });
    logAudit(
      "Card Unarchived",
      `Card ${task?.title} has been unarchived`,
      `unarchived ${task.title}`
    );
  };

  const handleLabelChange = (e, labelId) => {
    e.stopPropagation();
    const updatedSelectedLabels = selectedLabels.includes(labelId)
      ? selectedLabels.filter((id) => id !== labelId)
      : [...selectedLabels, labelId];

    setSelectedLabels(updatedSelectedLabels);

    const taskRef = ref(db, taskPath);
    update(taskRef, { labels: updatedSelectedLabels });
  };

  const handleAddLabelClick = () => {
    setLabelsModalOpen(true);
  };

  const handleAddAssignedClick = () => {
    setAssignedModalOpen(true);
  };

  const handleCloseLabelsModal = () => {
    setLabelsModalOpen(false);
  };

  const handleCloseAssignedModal = () => {
    setAssignedModalOpen(false);
  };

  const handleUnlinkCard = async () => {
    const taskOrderRef = ref(db, `${boardPath}/columns/${columnId}/taskOrder`);
    const taskRef = ref(db, taskPath);
    const realTaskRef = ref(db, realTaskPath);
    try {
      const snapshot = await get(taskOrderRef);
      let taskOrder = snapshot.val() || [];

      // Remove taskId from taskOrder
      taskOrder = taskOrder.filter((id) => id !== taskId);

      // Update taskOrder and remove the task
      await update(ref(db, `${boardPath}/columns/${columnId}`), { taskOrder });
      await remove(realTaskRef);

      setDeleteModalOpen(false);
      handleCloseModal();
    } catch (error) {
      console.error("Error unlinking task:", error);
    }
  };

  useEffect(() => {
    const taskRef = ref(db, taskPath);
    onValue(taskRef, (snapshot) => {
      const taskData = snapshot.val();
      if (taskData) {
        setTask(taskData);
        if (taskData.dueDate) {
          setSelectedDate(new Date(taskData.dueDate)); // Load due date into date picker
        }
      }
    });
  }, [taskId, columnId, boardPath, db, taskPath]);

  const handleDeleteTask = async () => {
    const taskRef = ref(db, taskPath);
    const taskOrderRef = isLinked
      ? ref(db, `${boardPath}/columns/${columnId}/taskOrder`)
      : ref(db, `${boardPath}/columns/${columnId}/taskOrder`);

    logToColumn(
      `Task "${task?.title}" deleted by ${
        currentUser.displayName || currentUser.email
      }`,
      columnId
    );
    try {
      const snapshot = await get(taskOrderRef);
      let taskOrder = snapshot.val() || [];

      // Remove taskId from taskOrder
      taskOrder = taskOrder.filter((id) => id !== taskId);

      // Update taskOrder and remove the task
      await update(ref(db, `${boardPath}/columns/${columnId}`), { taskOrder });
      await remove(taskRef);

      setDeleteModalOpen(false);
      onClose();
    } catch (error) {
      console.error("Error deleting task:", error);
      console.error("Audit entry could not be logged:", error);
    }
  };

  const updateTask = async (boardId, columnId, taskId, updates) => {
    const taskRef = ref(db, taskPath);
    await update(taskRef, updates);
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  function handleCloseModal() {
    if (!editingDescription && !openCoverPopover && !openPollPopover) {
      onClose();
    }
  }

  const handleAddChecklist = async () => {
    const newChecklist = {
      name: "New Checklist",
      items: [],
    };
    const checklistRef = ref(db, `${taskPath}/checklists`);
    const newChecklistRef = push(checklistRef);

    try {
      await update(newChecklistRef, newChecklist);
      setChecklists((prev) => ({
        ...prev,
        [newChecklistRef.key]: newChecklist,
      }));
      logAudit(
        "Checklist added",
        `A new checklist was added to the task.`,
        "added a checklist"
      );
    } catch (error) {
      console.error("Error adding checklist:", error);
    }
  };

  // Fetch task and checklists data
  useEffect(() => {
    const taskRef = ref(db, taskPath);
    onValue(taskRef, (snapshot) => {
      const taskData = snapshot.val();
      if (taskData) {
        setTask(taskData);
        setChecklists(taskData.checklists || []);
      }
    });
  }, [taskId, columnId, boardPath, db, taskPath]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        overflowY: "auto",
        zIndex: 1300,
      }}
      onMouseDown={handleCloseModal} // Only trigger handleCloseModal if not selecting text
    >
      <Box
        sx={{
          display: "flex",
          marginTop: "50px",
          marginBottom: "50px",
          flexDirection: "column",
          width: "clamp(200px, 750px, 750px)",
          height: "auto",
          padding: "20px 10px 20px 20px",
          backgroundColor:
            themeDetails[settings.cardColor]?.secondary || "#F3F4F5",
          color: themeDetails[settings.cardColor]?.font || "black",

          borderRadius: "20px",
          border: userDraggedFile && "dashed #A0AD39",
          opacity: userDraggedFile && "0.9",
          position: "relative", // Add this line
          overflow: "hidden", // Add this line
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {task?.cover && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "130px",
              background: task?.cover.startsWith("#")
                ? task?.cover
                : `url(${task?.cover}) no-repeat center center`,
              backgroundSize: "cover",
              zIndex: 0,
            }}
          />
        )}
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            backgroundColor: task?.cover ? "transparent" : "transparent",
            padding: task?.cover
              ? "10px 10px 20px 20px"
              : "20px 10px 20px 20px",
            borderRadius: "20px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              marginTop: task?.cover ? "110px" : 0,
            }}
          >
            <div style={{ width: "100%" }}>
              {editingTitle ? (
                <Box sx={{ width: "100%", display: "flex" }}>
                  <TeldioTextField
                    placeholderColor={
                      themeDetails[settings.cardColor]?.font || "darkgrey"
                    }
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSaveTitle();
                      }
                    }}
                    sx={{ width: "90%", marginRight: "2px" }}
                    size="small"
                  />
                  <IconButton
                    onClick={handleSaveTitle}
                    sx={{
                      color:
                        themeDetails[settings.cardColor]?.font || "darkgrey",
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Box>
              ) : (
                <Typography
                  variant="h5"
                  onClick={() => setEditingTitle(true)}
                  sx={{ cursor: "pointer" }}
                >
                  {title}
                </Typography>
              )}
            </div>
            {!isSmallScreen && (
              <div
                style={{
                  width: "8%",
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                }}
              >
                <Tooltip title={"Close"}>
                  <IconButton onClick={handleCloseModal}>
                    <CloseIcon
                      sx={{
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <Typography variant="subtitle2" mt={1}>
            In list{" "}
            <span
              style={{
                textDecoration: "underline",
                color: themeDetails[settings.cardColor]?.font || "black",
              }}
            >
              {columnName}
            </span>
            {task?.author && (
              <span
                style={{
                  marginLeft: "10px",
                  color: themeDetails[settings.cardColor]?.font || "black",
                }}
              >
                Created by{" "}
                <span style={{ textDecoration: "underline" }}>
                  {collaborators[task?.author]?.displayName ||
                    collaborators[task?.author]?.email}
                </span>
              </span>
            )}
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: isSmallScreen ? "100%" : "73%" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  gap: "10px",
                  margin: "10px 0px 15px 0px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: themeDetails[settings.cardColor]?.font || "black",
                      opacity: "0.8",
                      marginBottom: "0px",
                    }}
                    gutterBottom
                  >
                    Members
                  </Typography>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                    alignItems="center"
                  >
                    {assignedMembers.map((member) => (
                      <Tooltip
                        key={member.uid}
                        title={member?.displayName || member?.email}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            src={collaborators[member.uid]?.photoURL}
                            alt={member?.displayName || member?.email}
                          >
                            {member?.displayName?.substring(0, 1) ||
                              member?.email?.substring(0, 1)}
                          </Avatar>
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: -4,
                              right: -7,
                              backgroundColor: "white",
                              padding: 0,
                              border: "1px solid",
                            }}
                            onClick={() => handleUnassignMember(member)}
                          >
                            <CloseIcon
                              sx={{
                                fontSize: "13pt",
                                padding: "0px",
                                margin: "0px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    ))}
                    <Avatar
                      sx={{ cursor: "pointer" }}
                      onClick={handleAddAssignedClick}
                    >
                      <AddIcon />
                    </Avatar>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      marginBottom: "0px",
                      color: themeDetails[settings.cardColor]?.font || "black",
                      opacity: "0.8",
                    }}
                    gutterBottom
                  >
                    Labels
                  </Typography>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                    alignItems="center"
                  >
                    {selectedLabels.map((labelId) => {
                      const label = labels[labelId];
                      return (
                        <Box
                          key={labelId}
                          onClick={handleAddLabelClick}
                          sx={{
                            backgroundColor: label?.color,
                            borderRadius: "5px",
                            padding: "5px 10px",
                            color: getTextColor(label?.color || "#fff"),
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <Typography>{label?.name}</Typography>
                        </Box>
                      );
                    })}
                    <Box
                      sx={{
                        border: "1px dashed #ccc",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        width: "50px",
                        height: "34px",
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                      onClick={handleAddLabelClick}
                    >
                      <AddIcon />
                    </Box>
                  </Box>{" "}
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        marginBottom: "0px",
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                        opacity: "0.8",
                      }}
                      gutterBottom
                    >
                      Watch
                    </Typography>

                    <Tooltip
                      disableInteractive
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -14],
                              },
                            },
                          ],
                        },
                      }}
                      arrow
                      title={
                        isWatching
                          ? "You are currently watching this card for any updates"
                          : "Click to monitor this card for updates, updates will be emailed to you"
                      }
                    >
                      <SquaredButtonBase
                        startIcon={<VisibilityIcon />}
                        onClick={toggleWatchStatus}
                        endIcon={isWatching && <CheckBoxRoundedIcon />}
                        sx={{
                          backgroundColor:
                            themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                          color:
                            themeDetails[settings.cardColor]?.font || "black",
                        }}
                      >
                        {isWatching ? "Watching" : "Watch"}
                      </SquaredButtonBase>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        marginBottom: "0px",
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                        opacity: "0.8",
                      }}
                      gutterBottom
                    >
                      Due Date
                    </Typography>
                    <SquaredButtonBase
                      startIcon={<DateRangeIcon />}
                      onClick={handleOpenDueDatePopover}
                      sx={{
                        backgroundColor: (() => {
                          if (task?.dueDate) {
                            const dueDate = new Date(task?.dueDate);
                            const now = new Date();
                            const timeDifference = dueDate - now;
                            const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;

                            if (timeDifference < 0) {
                              // Due date has passed
                              return "#FF6347"; // Red background for passed due date
                            } else if (timeDifference <= oneWeekInMillis) {
                              // Due date is within a week
                              return "#FFBF00"; // Amber background for upcoming due date
                            }
                          }
                          return (
                            themeDetails[settings.cardColor]?.alt || "#E3E6EA"
                          ); // Default background color
                        })(),
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                        "&:hover": {
                          backgroundColor: (() => {
                            if (task?.dueDate) {
                              const dueDate = new Date(task?.dueDate);
                              const now = new Date();
                              const timeDifference = dueDate - now;
                              const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;

                              if (timeDifference < 0) {
                                // Tint for passed due date (red)
                                return "rgba(255, 99, 71, 0.8)"; // Lighter red with 80% opacity
                              } else if (timeDifference <= oneWeekInMillis) {
                                // Tint for upcoming due date (amber)
                                return "rgba(255, 191, 0, 0.8)"; // Lighter amber with 80% opacity
                              }
                            }
                            return "rgba(227, 230, 234, 0.8)"; // Default background color tint
                          })(),
                        },
                      }}
                    >
                      {task?.dueDate
                        ? new Date(task?.dueDate).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                        : "Due Date"}
                    </SquaredButtonBase>
                  </Box>
                </Box>
              </div>
              {/* <Description
                editingDescription={editingDescription}
                setEditingDescription={setEditingDescription}
                description={task?.description || ""}
                setDescription={(newDescription) =>
                  setTask((prev) => ({ ...prev, description: newDescription }))
                }
                taskId={taskId}
                boardId={boardId}
                columnId={columnId}
                boardOwner={boardOwner}
                updateTask={updateTask}
                collaborators={convertToArray(collaborators)}
              /> */}
              <RichDescription
                task={task}
                editingDescription={editingDescription}
                setEditingDescription={setEditingDescription}
                description={task?.description || ""}
                setDescription={(newDescription) =>
                  setTask((prev) => ({
                    ...prev,
                    description: newDescription,
                  }))
                }
                taskId={taskId}
                boardId={boardId}
                columnId={columnId}
                boardOwner={boardOwner}
                updateTask={updateTask}
                collaborators={convertToArray(collaborators)}
                taskPath={taskPath}
                handleCloseModal={handleCloseModal}
                logAudit={logAudit}
                taskName={task?.title}
              />
              {task?.attachmentsShow && (
                <Box sx={{ marginTop: "20px" }}>
                  <Attachments
                    attachments={attachments}
                    setAttachments={setAttachments}
                    boardId={boardId}
                    columnId={columnId}
                    taskId={taskId}
                    boardOwner={boardOwner}
                    logAudit={logAudit}
                    taskPath={taskPath}
                  />
                </Box>
              )}
              {task?.imagesShow && (
                <Box sx={{ marginTop: "20px" }}>
                  <Images
                    images={images}
                    setImages={setImages}
                    boardId={boardId}
                    columnId={columnId}
                    taskId={taskId}
                    boardOwner={boardOwner}
                    logAudit={logAudit}
                    taskPath={taskPath}
                    handleFileUpload={handleFileUpload}
                    setUploading={setUploading}
                    uploading={uploading}
                  />
                </Box>
              )}
              <Box sx={{ marginTop: "20px" }}>
                <Checklist
                  checklists={convertToArray(checklists)}
                  setChecklists={setChecklists}
                  boardId={boardId}
                  columnId={columnId}
                  taskId={taskId}
                  boardOwner={boardOwner}
                  logAudit={logAudit}
                  taskPath={taskPath}
                  collaborators={convertToArray(collaborators)}
                  task={task}
                  boardTitle={boardTitle}
                />
              </Box>
              {task?.polls &&
                convertToArray(task?.polls).length > 0 &&
                convertToArray(task?.polls).map((poll) => (
                  <Box sx={{ marginTop: "20px" }}>
                    <Poll
                      poll={poll}
                      pollPath={`${taskPath}/polls/${poll.uid}`}
                      boardId={boardId}
                      columnId={columnId}
                      taskId={taskId}
                      boardOwner={boardOwner}
                      logAudit={logAudit}
                      collaborators={collaborators}
                      taskPath={taskPath}
                    />
                  </Box>
                ))}
              {task?.linksShow && (
                <Box>
                  <Links
                    collaborators={collaborators}
                    comments={comments}
                    setComments={setComments}
                    boardId={boardId}
                    columnId={columnId}
                    taskId={taskId}
                    boardOwner={boardOwner}
                    logAudit={logAudit}
                    taskName={task?.title}
                    taskPath={taskPath}
                  />
                </Box>
              )}
              {!settings.mergeComments && (
                <Box>
                  <History
                    collaborators={collaborators}
                    comments={comments}
                    setComments={setComments}
                    boardId={boardId}
                    columnId={columnId}
                    taskId={taskId}
                    boardOwner={boardOwner}
                    logAudit={logAudit}
                    taskName={task?.title}
                    boardTitle={boardTitle}
                    taskTitle={task?.title}
                    taskPath={taskPath}
                    audit={convertToArray(task?.audit)
                      ?.filter(
                        (item) =>
                          item.action !== "Comment added" &&
                          item.action !== "Reaction added" &&
                          item.action !== "Reply added"
                      )
                      .reverse()}
                  />
                </Box>
              )}
              <Box>
                {/* <Comments
                  collaborators={collaborators}
                  comments={comments}
                  setComments={setComments}
                  boardId={boardId}
                  columnId={columnId}
                  taskId={taskId}
                  boardOwner={boardOwner}
                  logAudit={logAudit}
                  taskName={task?.title}
                  boardTitle={boardTitle}
                  taskTitle={task?.title}
                  taskPath={taskPath}
                /> */}
                <RichComments
                  task={task}
                  collaborators={convertToArray(collaborators)}
                  comments={
                    settings.mergeComments
                      ? mergeAndSort(
                          comments,
                          convertToArray(task?.audit)
                            ?.filter(
                              (item) =>
                                item.action !== "Comment added" &&
                                item.action !== "Reaction added" &&
                                item.action !== "Reply added" &&
                                item.action !== "Edited Description"
                            )
                            .reverse()
                        )
                      : mergeAndSort(comments, [])
                  }
                  setComments={setComments}
                  boardId={boardId}
                  columnId={columnId}
                  taskId={taskId}
                  boardOwner={boardOwner}
                  logAudit={logAudit}
                  taskName={task?.title}
                  boardTitle={boardTitle}
                  taskTitle={task?.title}
                  taskPath={taskPath}
                />
              </Box>
            </div>
            <div
              style={{
                width: isSmallScreen ? "0" : "23%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {isSmallScreen ? (
                <>
                  <div style={{ position: "absolute", top: 10, right: 0 }}>
                    <IconButton
                      onClick={handleMenuClick}
                      sx={{
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>

                  <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    handleCloseModal={handleMenuClose}
                  >
                    <MenuItem onClick={handleCloseModal}>
                      <CloseIcon />
                      Close
                    </MenuItem>
                    <Typography sx={{ marginBottom: "5px", px: 2 }}>
                      Add to Card
                    </Typography>
                    <MenuItem
                      onClick={() => {
                        setLabelsModalOpen(true);
                        handleMenuClose();
                      }}
                    >
                      <LabelIcon />
                      Labels
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAssignedModalOpen(true);
                        handleMenuClose();
                      }}
                    >
                      <GroupIcon />
                      Members
                    </MenuItem>
                    <MenuItem onClick={(e) => handleOpenCoverPopover(e)}>
                      <VideoLabelIcon />
                      Cover
                    </MenuItem>
                    <MenuItem onClick={handleAddChecklist}>
                      <CheckBoxIcon />
                      {"Add Checklist"}
                    </MenuItem>
                    <MenuItem
                      onClick={
                        task?.attachmentsShow
                          ? handleHideAttachments
                          : handleShowAttachments
                      }
                    >
                      <AttachFileIcon />
                      {task?.attachmentsShow
                        ? "Hide Attachments"
                        : "Attachments"}
                    </MenuItem>
                    <MenuItem
                      onClick={
                        task?.linksShow ? handleHideLinks : handleShowLinks
                      }
                    >
                      <LinkIcon />
                      {task?.linksShow ? "Hide Links" : "Links"}
                    </MenuItem>
                    <MenuItem onClick={handleOpenPollPopover}>
                      <PollIcon />
                      Add Poll
                    </MenuItem>
                    <MenuItem
                      onClick={
                        task?.imagesShow ? handleHideImages : handleShowImages
                      }
                    >
                      <ImageIcon />
                      {task?.imagesShow ? "Hide Images" : "Add Images"}
                    </MenuItem>
                    <Divider />
                    <Typography sx={{ marginBottom: "5px", px: 2 }}>
                      Actions
                    </Typography>
                    {!task?.archived ? (
                      <MenuItem onClick={handleArchiveCard}>
                        <ArchiveIcon />
                        Archive Card
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={handleUnArchiveCard}>
                        <ArchiveIcon />
                        Unarchive Card
                      </MenuItem>
                    )}
                    {isLinked ? (
                      <MenuItem
                        onClick={() => {
                          // console.log(realTaskPath);
                          setDeleteModalOpen(realTaskPath);
                        }}
                      >
                        <LinkOffIcon />
                        Unlink Card
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          setDeleteModalOpen(true);
                        }}
                      >
                        <DeleteIcon />
                        Delete Card
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleOpenCopyLink}>
                      <ShareIcon />
                      Share Card
                    </MenuItem>
                    {!isLinked && (
                      <>
                        <MenuItem onClick={handleCopyCard}>
                          <ContentCopyIcon />
                          Copy Card
                        </MenuItem>
                      </>
                    )}
                    <MenuItem onClick={(e) => handleOpenMovePopover(e)}>
                      <DriveFileMoveIcon />
                      Move Card
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Typography sx={{ marginBottom: "5px" }}>
                    Add to Card
                  </Typography>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    onClick={() => {
                      setLabelsModalOpen(true);
                    }}
                    startIcon={<LabelIcon />}
                  >
                    Labels
                  </TaskModalButtonBase>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    onClick={() => {
                      setAssignedModalOpen(true);
                    }}
                    startIcon={<GroupIcon />}
                  >
                    Members
                  </TaskModalButtonBase>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    aria-describedby={id}
                    onClick={(e) => handleOpenCoverPopover(e)}
                    startIcon={<VideoLabelIcon />}
                  >
                    Cover
                  </TaskModalButtonBase>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    onClick={handleAddChecklist}
                    startIcon={<CheckBoxIcon />}
                  >
                    Add Checklist
                  </TaskModalButtonBase>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    aria-describedby={id}
                    onClick={
                      task?.attachmentsShow
                        ? handleHideAttachments
                        : handleShowAttachments
                    }
                    startIcon={<AttachFileIcon />}
                  >
                    {task?.attachmentsShow ? "Hide Attachments" : "Attachments"}
                  </TaskModalButtonBase>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    aria-describedby={id}
                    onClick={
                      task?.linksShow ? handleHideLinks : handleShowLinks
                    }
                    startIcon={<LinkIcon />}
                  >
                    {task?.linksShow ? "Hide Links" : "Links"}
                  </TaskModalButtonBase>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    aria-describedby={id}
                    onClick={handleOpenPollPopover}
                    startIcon={<PollIcon />}
                  >
                    Add Poll
                  </TaskModalButtonBase>
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    aria-describedby={id}
                    onClick={
                      task?.imagesShow ? handleHideImages : handleShowImages
                    }
                    startIcon={<ImageIcon />}
                  >
                    {task?.imagesShow ? "Hide Images" : "Add Images"}
                  </TaskModalButtonBase>
                  <Typography sx={{ marginBottom: "5px" }}>Actions</Typography>
                  {!task?.archived && (
                    <TaskModalButtonBase
                      sx={{
                        backgroundColor:
                          themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                      fullWidth
                      onClick={handleArchiveCard}
                      startIcon={<ArchiveIcon />}
                    >
                      Archive Card
                    </TaskModalButtonBase>
                  )}
                  {task?.archived === true && (
                    <TaskModalButtonBase
                      sx={{
                        backgroundColor:
                          themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                      fullWidth
                      onClick={handleUnArchiveCard}
                      startIcon={<ArchiveIcon />}
                    >
                      Unarchive Card
                    </TaskModalButtonBase>
                  )}
                  {isLinked ? (
                    <TaskModalButtonBase
                      sx={{
                        backgroundColor:
                          themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                      fullWidth
                      onClick={() => {
                        // console.log(realTaskPath);
                        setDeleteModalOpen(realTaskPath);
                      }}
                      startIcon={<LinkOffIcon />}
                    >
                      Unlink Card
                    </TaskModalButtonBase>
                  ) : (
                    <TaskModalButtonBase
                      sx={{
                        backgroundColor:
                          themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                      fullWidth
                      onClick={() => {
                        setDeleteModalOpen(true);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Card
                    </TaskModalButtonBase>
                  )}
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    onClick={() => {
                      handleOpenCopyLink();
                    }}
                    startIcon={<ShareIcon />}
                  >
                    Share Card
                  </TaskModalButtonBase>
                  {!isLinked && (
                    <TaskModalButtonBase
                      sx={{
                        backgroundColor:
                          themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                        color:
                          themeDetails[settings.cardColor]?.font || "black",
                      }}
                      fullWidth
                      onClick={() => {
                        handleCopyCard();
                      }}
                      startIcon={<ContentCopyIcon />}
                    >
                      Copy Card
                    </TaskModalButtonBase>
                  )}
                  <TaskModalButtonBase
                    sx={{
                      backgroundColor:
                        themeDetails[settings.cardColor]?.alt || "#E3E6EA",
                      color: themeDetails[settings.cardColor]?.font || "black",
                    }}
                    fullWidth
                    onClick={(e) => handleOpenMovePopover(e)}
                    startIcon={<DriveFileMoveIcon />}
                  >
                    Move Card
                  </TaskModalButtonBase>
                </>
              )}
            </div>
          </div>
        </Box>

        <Modal
          open={labelsModalOpen}
          onClose={handleCloseLabelsModal}
          aria-labelledby="labels-modal-title"
          aria-describedby="labels-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              borderRadius: "5px",
              p: 2,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography id="labels-modal-title" variant="h6" component="h2">
                Select Labels
              </Typography>
              <IconButton onClick={handleCloseLabelsModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <List>
              {Object.keys(labels).length === 0 && (
                <Typography sx={{ textAlign: "center" }}>
                  Add labels to get started
                </Typography>
              )}
              {Object.keys(labels).map((labelId) => {
                const label = labels[labelId];
                return (
                  <ListItem key={labelId} sx={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 0,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        document.getElementById(`checkbox-${labelId}`).click()
                      }
                    >
                      <FormControlLabel
                        sx={{ marginRight: "1px" }}
                        control={
                          <TeldioCheckbox
                            id={`checkbox-${labelId}`}
                            checked={selectedLabels.includes(labelId)}
                            onChange={(e) => handleLabelChange(e, labelId)}
                          />
                        }
                      />
                      <div
                        style={{
                          backgroundColor: label?.color,
                          borderRadius: "5px",
                          padding: "2px 5px 2px 5px",
                          color: "#fff",
                          width: "260px",
                          height: "30px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color={getTextColor(label?.color)}>
                          {label?.name}
                        </Typography>
                      </div>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Modal>

        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              borderRadius: "5px",
              p: 2,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography id="delete-modal-title" variant="h6" component="h2">
              {isLinked ? `Confirm Unlink` : `Confirm Delete`}
            </Typography>
            <Typography id="delete-modal-description" sx={{ mt: 2 }}>
              {isLinked
                ? `Are you sure you want to unlink this card?`
                : `Are you sure you want to delete this task and all of it's data?`}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <SquaredButton
                variant="contained"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </SquaredButton>
              <Button
                variant="contained"
                color="error"
                onClick={isLinked ? handleUnlinkCard : handleDeleteTask}
              >
                {isLinked ? `Unlink` : `Delete`}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={assignedModalOpen}
          onClose={handleCloseAssignedModal}
          aria-labelledby="assigned-modal-title"
          aria-describedby="assigned-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              borderRadius: "5px",
              p: 2,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography id="assigned-modal-title" variant="h6" component="h2">
                Assign Members
              </Typography>
              <IconButton onClick={handleCloseAssignedModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <List>
              {collaborators &&
                convertToArray(collaborators).map((member) =>
                  assignedMembers?.some(
                    (assigned) => assigned.uid === member.uid
                  ) ? (
                    <ListItemButton
                      key={member?.uid}
                      onClick={() => handleUnassignMember(member)}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={collaborators[member.uid]?.photoURL}
                          alt={
                            member?.displayName ||
                            collaborators[member.uid].email
                          }
                        />
                        <Typography sx={{ ml: 2 }}>
                          {member?.displayName || member?.email}
                        </Typography>
                      </div>
                      <CloseIcon color="error" />
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      key={member?.uid}
                      onClick={() => handleAssignMember(member)}
                    >
                      <Avatar
                        src={member?.photoURL}
                        alt={member?.displayName}
                      />
                      <Typography sx={{ ml: 2 }}>
                        {member?.displayName || member?.email}
                      </Typography>
                    </ListItemButton>
                  )
                )}
            </List>
          </Box>
        </Modal>
        <Snackbar
          open={openCopyLink}
          autoHideDuration={5000}
          onClose={handleCloseCopyLink}
          message={openCopyLink}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
      </Box>
      <Popover
        id={id}
        open={openCoverPopover} // This should be the boolean that tracks if the popover is open
        anchorEl={anchorEl}
        onClose={handleCloseCoverPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Cover
          cover={task?.cover}
          closeCover={handleCloseCoverPopover}
          handleSaveCover={handleSaveCover}
          taskId={taskId}
          onClick={(e) => e.stopPropagation()}
        />
      </Popover>

      <Popover
        id={moveID}
        open={openMovePopover}
        anchorEl={moveAnchorEl}
        onClose={handleCloseMovePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          onMouseDown={(e) => e.stopPropagation()} // Prevent mouse down event propagation
          onMouseUp={(e) => e.stopPropagation()} // Prevent mouse up event propagation
          onClick={(e) => e.stopPropagation()} // Prevent click event propagation
        >
          <MovePopover
            closePopover={handleCloseMovePopover}
            handleMoveTask={handleMoveTask}
            taskId={taskId}
            ownBoards={ownBoards}
            collaboratedBoards={collaboratedBoards}
            boardTitle={boardTitle}
            boardPath={boardPath}
            columnId={columnId}
          />
        </Box>
      </Popover>

      <Popover
        id={pollID}
        open={openPollPopover} // This should be the boolean for opening the poll popover
        anchorEl={pollAnchorEl}
        onClose={handleClosePollPopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            maxHeight: "502px",
            overflowY: "auto", // Allow scrolling if content overflows
          },
        }}
      >
        <PollPopover
          boardPath={boardPath}
          columnId={columnId}
          taskId={taskId}
          logAudit={logAudit}
          taskName={task?.title}
        />
      </Popover>

      <Popover
        id={dueDateID}
        open={open}
        anchorEl={dueDateAnchorEl}
        onClose={handleCloseDueDatePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onMouseDown={(e) => e.stopPropagation()} // Prevent mouse down event propagation
        onMouseUp={(e) => e.stopPropagation()} // Prevent mouse up event propagation
      >
        <Box
          sx={{
            p: 2,
          }}
          onClick={(e) => e.stopPropagation()} // Prevent click event propagation
          onMouseDown={(e) => e.stopPropagation()} // Ensure mouse down doesn't trigger modal close
          onMouseUp={(e) => e.stopPropagation()} // Ensure mouse up doesn't trigger modal close
        >
          <ReactDatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            dateFormat="Pp"
            inline
            onClick={(e) => e.stopPropagation()} // Prevent click event propagation
            onMouseDown={(e) => e.stopPropagation()} // Prevent mouse down inside date picker
            onMouseUp={(e) => e.stopPropagation()} // Prevent mouse up inside date picker
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            {task?.dueDate && (
              <SquaredButtonBase
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveDueDate();
                }}
                variant="contained"
                color="error"
              >
                Remove Due Date
              </SquaredButtonBase>
            )}
            <SquaredButton
              onClick={(e) => {
                e.stopPropagation();
                handleCloseDueDatePopover();
              }}
              variant="contained"
            >
              Cancel
            </SquaredButton>
            <SquaredButtonGreen
              onClick={(e) => {
                e.stopPropagation();
                handleSaveDueDate();
              }}
              variant="contained"
            >
              Save
            </SquaredButtonGreen>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};

export default TaskModal;
