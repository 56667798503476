import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  Checkbox,
  IconButton,
  Stack,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Modal,
  Divider,
  Avatar,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { ref, update, get, remove } from "firebase/database";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { getDB } from "../../../firebase/firebase";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  SquaredButtonGray,
  SquaredButtonGreen,
  TeldioCheckbox,
  TeldioLinearProgress,
  TeldioTextField,
} from "../../../MUI_Components/Components";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ChecklistIcon from "@mui/icons-material/Checklist";
import EditIcon from "@mui/icons-material/Edit";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { sendEmail } from "../../../Helper/utils";
import { useSettings } from "../../../Contexts/SettingsContext";
import "./Checklist.css";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useThemeContext } from "../../../Contexts/ThemeDetailsContext";

const Checklist = ({
  checklists,
  setChecklists,
  boardId,
  columnId,
  taskId,
  boardOwner,
  logAudit,
  taskPath,
  collaborators,
  task,
  boardTitle,
}) => {
  const { currentUser } = useAuth();
  const db = getDB();
  const [newItem, setNewItem] = useState("");
  const [renamingItem, setRenamingItem] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [addingItemIndex, setAddingItemIndex] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedItem, setSelectedItem] = useState({ index: null, text: "" });
  const [renamingIndex, setRenamingIndex] = useState(null);
  const [renamingChecklist, setRenamingChecklist] = useState(null);
  const [renamingText, setRenamingText] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [checklistToDelete, setChecklistToDelete] = useState(null);
  const { settings } = useSettings();
  const [openCollaboratorModal, setOpenCollaboratorModal] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [currentChecklistIndex, setCurrentChecklistIndex] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const themeDetails = useThemeContext();

  useEffect(() => {
    const moveChecklist = async () => {
      const checklistRef = ref(db, `${taskPath}/checklist`);
      const checklistsRef = ref(db, `${taskPath}/checklists`);

      try {
        const snapshot = await get(checklistRef);
        if (snapshot.exists()) {
          const checklistData = snapshot.val();

          const updates = {};
          updates[`checklists/0`] = { name: "Checklist", items: checklistData };
          updates[`checklist`] = null;

          await update(ref(db, taskPath), updates);
          console.log("Checklist moved successfully");

          setChecklists([{ name: "Checklist", items: checklistData }]);
        }
      } catch (error) {
        console.error("Error moving checklist:", error);
      }
    };

    moveChecklist();

    const fetchChecklists = async () => {
      try {
        const checklistsRef = ref(db, `${taskPath}/checklists`);
        const snapshot = await get(checklistsRef);
        if (snapshot.exists()) {
          setChecklists(snapshot.val());
        } else {
          setChecklists([]);
        }
      } catch (error) {
        console.error("Error fetching checklists:", error);
        setChecklists([]);
      }
    };

    fetchChecklists();
  }, [db, taskPath, setChecklists]);

  const handleRightClick = (event, checklistIndex, itemIndex, text) => {
    event.preventDefault();
    setSelectedItem({ checklistIndex, itemIndex, text });
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDelete = () => {
    handleDeleteItem(selectedItem.checklistIndex, selectedItem.itemIndex);
    handleClose();
  };

  const handleAddChecklistItem = (checklist) => {
    if (newItem.length === 0) {
      return;
    }

    const itemsArray = checklist.items || [];
    const updatedItems = [...itemsArray, { text: newItem, completed: false }];
    const checklistIndex = checklists.findIndex(
      (cl) => cl.uid === checklist.uid
    );

    if (checklistIndex !== -1) {
      const updatedChecklists = [...checklists];
      updatedChecklists[checklistIndex].items = updatedItems;
      setChecklists(updatedChecklists);

      const taskRef = ref(db, `${taskPath}/checklists/${checklist.uid}`);
      update(taskRef, { items: updatedItems });

      logAudit(
        "Checklist item added",
        `${newItem} has been added to the checklist`,
        `added ${newItem} to a checklist`
      );

      setNewItem("");
      setAddingItemIndex(null);
    } else {
      console.error("Checklist not found for adding item.");
    }
  };

  const handleToggleChecklistItem = (checklist, itemIndex) => {
    const checklistIndex = checklists.findIndex(
      (cl) => cl.uid === checklist.uid
    );

    if (checklistIndex !== -1) {
      const updatedItems = [...checklists[checklistIndex].items];
      const state = !updatedItems[itemIndex].completed;
      updatedItems[itemIndex].completed = state;

      const updatedChecklists = [...checklists];
      updatedChecklists[checklistIndex].items = updatedItems;
      setChecklists(updatedChecklists);

      const taskRef = ref(db, `${taskPath}/checklists/${checklist.uid}`);
      update(taskRef, { items: updatedItems });

      logAudit(
        `Checklist item ${state ? "checked" : "unchecked"}`,
        `${
          updatedItems[itemIndex].text.length > 0
            ? updatedItems[itemIndex].text
            : "Empty"
        } has been set to ${state ? "checked" : "unchecked"}`,
        `set ${
          updatedItems[itemIndex].text.length > 0
            ? updatedItems[itemIndex].text
            : "Empty"
        } to ${state ? "checked" : "unchecked"}`
      );
    } else {
      console.error("Checklist not found for toggling item.");
    }
  };

  const handleChecklistItemChange = (checklistIndex, itemIndex, text) => {
    const updatedChecklists = [...checklists];
    const checklist = updatedChecklists[checklistIndex];

    if (!checklist || !checklist.uid) {
      console.error("Checklist not found or invalid UID.");
      return;
    }

    const newChecklistItems = [...checklist.items];
    newChecklistItems[itemIndex].text = text;

    updatedChecklists[checklistIndex].items = newChecklistItems;
    setChecklists(updatedChecklists);

    const taskRef = ref(db, `${taskPath}/checklists/${checklist.uid}`);
    update(taskRef, { items: newChecklistItems }).catch((error) => {
      console.error("Error updating checklist items:", error);
    });
  };

  const handleSaveItem = (checklistIndex, itemIndex, text) => {
    setRenamingItem(null);
    handleChecklistItemChange(checklistIndex, itemIndex, text);
  };

  const handleDeleteItem = (checklistIndex, itemIndex) => {
    setRenamingItem(null);
    const checklist = checklists[checklistIndex];
    if (!checklist || !checklist.uid) {
      console.error("Checklist not found or invalid UID.");
      return;
    }

    const newItems = [...checklist.items];
    newItems.splice(itemIndex, 1);

    const updatedChecklists = [...checklists];
    updatedChecklists[checklistIndex].items = newItems;
    setChecklists(updatedChecklists);

    const taskRef = ref(db, `${taskPath}/checklists/${checklist.uid}`);
    update(taskRef, { items: newItems });
  };

  const handleModifyMembers = (checklistIndex, itemIndex) => {
    const item = checklists[checklistIndex].items[itemIndex];
    setSelectedCollaborators(item.collaborators || []);
    setCurrentChecklistIndex(checklistIndex);
    setCurrentItemIndex(itemIndex);
    setOpenCollaboratorModal(true);
  };

  const handleToggleCollaborator = (uid) => {
    setSelectedCollaborators((prev) =>
      prev.includes(uid) ? prev.filter((id) => id !== uid) : [...prev, uid]
    );
  };

  const notifyNewAssignee = async (newAssigneeId, taskId, checklistItem) => {
    let user = collaborators.filter((user) => user.uid === newAssigneeId)[0];

    logAudit(
      "Member Assigned",
      `${
        user.displayName || user.email
      } has been assigned to checklist item ${checklistItem} in ${task.title}`,
      `assigned ${
        user.displayName || user.email
      } to checklist item ${checklistItem} in ${task.title}`
    );

    if (newAssigneeId !== currentUser.uid) {
      sendEmail(user.email, "assigned to checklist item", {
        boardName: boardTitle,
        fromEmail: currentUser.email,
        cardName: task.title,
        boardID: boardId,
        cardID: taskId,
        checklistItem: checklistItem,
      });

      const functions = getFunctions();
      const addActivity = httpsCallable(functions, "addActivity");

      await addActivity({
        boardOwner: boardOwner === "own" ? currentUser.uid : boardOwner,
        boardId,
        mentionedUser: user.email,
        displayName: user.displayName || user.email.split("@")[0],
        taskId,
        columnId,
        type: "assign",
        mentionedBy: currentUser.uid,
      });
    }
  };

  const handleSaveCollaborators = async () => {
    if (currentChecklistIndex !== null && currentItemIndex !== null) {
      const updatedChecklists = [...checklists];
      const previousCollaborators =
        updatedChecklists[currentChecklistIndex].items[currentItemIndex]
          .collaborators || [];

      updatedChecklists[currentChecklistIndex].items[
        currentItemIndex
      ].collaborators = selectedCollaborators;

      setChecklists(updatedChecklists);

      const taskRef = ref(
        db,
        `${taskPath}/checklists/${checklists[currentChecklistIndex].uid}`
      );
      await update(taskRef, {
        items: updatedChecklists[currentChecklistIndex].items,
      });

      const newCollaborators = selectedCollaborators.filter(
        (uid) => !previousCollaborators.includes(uid)
      );

      newCollaborators.forEach((uid) => {
        notifyNewAssignee(
          uid,
          taskId,
          updatedChecklists[currentChecklistIndex].items[currentItemIndex].text
        );
      });

      setOpenCollaboratorModal(false);
    }
  };

  const handleRenameChecklist = (checklist, checklistIndex) => {
    setRenamingIndex(checklistIndex);
    setRenamingChecklist(checklist);
    setRenamingText(checklists[checklistIndex].name);
  };

  const handleSaveChecklistName = (checklist) => {
    if (renamingText?.trim() === "") {
      return;
    }
    const updatedChecklists = [...checklists];
    const checklistIndex = updatedChecklists.findIndex(
      (cl) => cl.uid === checklist.uid
    );

    if (checklistIndex !== -1) {
      updatedChecklists[checklistIndex].name = renamingText;
      setChecklists(updatedChecklists);
      setRenamingIndex(null);

      const taskRef = ref(db, `${taskPath}/checklists/${checklist.uid}`);
      update(taskRef, { name: renamingText })
        .then(() => {
          logAudit(
            "Checklist renamed",
            `Checklist renamed to ${renamingText}`,
            `renamed checklist to ${renamingText}`
          );
        })
        .catch((error) => {
          console.error("Error renaming checklist:", error);
        });
    } else {
      console.error("Checklist not found");
    }
  };

  const handleDeleteChecklist = (checklist) => {
    setChecklistToDelete(checklist.uid);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteChecklist = async () => {
    if (checklistToDelete === null) return;

    try {
      const checklistRef = ref(
        db,
        `${taskPath}/checklists/${checklistToDelete}`
      );
      await remove(checklistRef);

      const updatedChecklists = [...checklists].filter(
        (cl) => cl.uid !== checklistToDelete
      );
      setChecklists(updatedChecklists);

      logAudit(
        "Checklist deleted",
        `Checklist ${
          checklists.find((cl) => cl.uid === checklistToDelete)?.name ||
          checklistToDelete
        } has been deleted`,
        `deleted checklist ${
          checklists.find((cl) => cl.uid === checklistToDelete)?.name ||
          checklistToDelete
        }`
      );

      setDeleteDialogOpen(false);
      setChecklistToDelete(null);
    } catch (error) {
      console.error("Error deleting checklist:", error);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceChecklistIndex = parseInt(
      result.source.droppableId.split("-")[1],
      10
    );
    const destinationChecklistIndex = parseInt(
      result.destination.droppableId.split("-")[1],
      10
    );

    // Create deep copies of the checklists to avoid reference issues
    const updatedChecklists = JSON.parse(JSON.stringify(checklists));

    // Ensure items arrays exist
    if (!updatedChecklists[sourceChecklistIndex].items) {
      updatedChecklists[sourceChecklistIndex].items = [];
    }
    if (!updatedChecklists[destinationChecklistIndex].items) {
      updatedChecklists[destinationChecklistIndex].items = [];
    }

    // Remove the item from source
    const [movedItem] = updatedChecklists[sourceChecklistIndex].items.splice(
      result.source.index,
      1
    );

    // Insert into destination
    updatedChecklists[destinationChecklistIndex].items.splice(
      result.destination.index,
      0,
      movedItem
    );

    // Update Firebase - Use a single transaction for both updates
    const updates = {};

    // Only update if there's a valid UID
    if (updatedChecklists[sourceChecklistIndex].uid) {
      updates[
        `${taskPath}/checklists/${updatedChecklists[sourceChecklistIndex].uid}/items`
      ] = updatedChecklists[sourceChecklistIndex].items;
    }

    if (
      sourceChecklistIndex !== destinationChecklistIndex &&
      updatedChecklists[destinationChecklistIndex].uid
    ) {
      updates[
        `${taskPath}/checklists/${updatedChecklists[destinationChecklistIndex].uid}/items`
      ] = updatedChecklists[destinationChecklistIndex].items;
    }

    // Only update Firebase if we have changes to make
    if (Object.keys(updates).length > 0) {
      update(ref(db), updates)
        .then(() => {
          console.log("Checklists updated successfully in Firebase");
        })
        .catch((error) => {
          console.error("Error updating checklists in Firebase:", error);
          // Revert state on error
          setChecklists(checklists);
        });
    }

    // Update local state
    setChecklists(updatedChecklists);
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        {checklists.map((checklist, checklistIndex) => {
          const completedCount = checklist?.items
            ? checklist.items.filter((item) => item.completed).length
            : 0;
          const progress =
            checklist?.items && checklist.items.length > 0
              ? (completedCount / checklist.items.length) * 100
              : 0;

          return (
            <Box
              key={checklist.uid || checklistIndex}
              sx={{ marginBottom: "20px" }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <ChecklistIcon />
                {renamingIndex === checklistIndex ? (
                  <Box display="flex" alignItems="center">
                    <TeldioTextField
                      value={renamingText}
                      onChange={(e) => setRenamingText(e.target.value)}
                      size="small"
                      autoFocus
                      sx={{ width: "200px" }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSaveChecklistName(checklist);
                          e.stopPropagation();
                        }
                        if (e.key === "Escape") {
                          setRenamingIndex(null);
                          e.stopPropagation();
                        }
                      }}
                    />
                    <IconButton
                      onClick={() => handleSaveChecklistName(checklist)}
                      color="success"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleRenameChecklist(checklist, checklistIndex)
                      }
                    >
                      {checklist.name || `Checklist ${checklistIndex}`}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleRenameChecklist(checklist, checklistIndex)
                      }
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
                <IconButton
                  onClick={() => handleDeleteChecklist(checklist)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2">{`${Math.round(
                    progress
                  )}%`}</Typography>
                </Box>
                <Box sx={{ width: "100%", ml: 1 }}>
                  <TeldioLinearProgress
                    variant="determinate"
                    value={progress}
                  />
                </Box>
              </Box>
              <Droppable droppableId={`checklist-${checklistIndex}`}>
                {(provided) => (
                  <List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ minHeight: "50px" }}
                  >
                    {checklist?.items?.map((item, itemIndex) => (
                      <Draggable
                        key={`${checklist.uid}-${itemIndex}-${item.text}`}
                        draggableId={`item-${checklist.uid}-${itemIndex}`}
                        index={itemIndex}
                      >
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onContextMenu={(event) =>
                              handleRightClick(
                                event,
                                checklistIndex,
                                itemIndex,
                                item.text
                              )
                            }
                          >
                            <Checkbox
                              sx={{
                                color: "#A0AD39",
                                "&.Mui-checked": {
                                  color: "#A0AD39",
                                },
                              }}
                              checked={item.completed}
                              onChange={() =>
                                handleToggleChecklistItem(checklist, itemIndex)
                              }
                            />
                            {renamingItem &&
                            renamingItem.checklistIndex === checklistIndex &&
                            renamingItem.itemIndex === itemIndex ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                flexGrow="1"
                              >
                                <TeldioTextField
                                  placeholderColor={
                                    themeDetails[settings.cardColor]?.font ||
                                    "darkgrey"
                                  }
                                  value={editingText}
                                  size="small"
                                  autoFocus
                                  sx={{ width: "100%" }}
                                  onChange={(e) =>
                                    setEditingText(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleSaveItem(
                                        checklistIndex,
                                        itemIndex,
                                        editingText
                                      );
                                      e.stopPropagation();
                                    }
                                    if (e.key === "Escape") {
                                      setRenamingItem(null);
                                      e.stopPropagation();
                                    }
                                  }}
                                />
                                <IconButton
                                  sx={{
                                    color:
                                      themeDetails[settings.cardColor]?.font ||
                                      "darkgrey",
                                  }}
                                  onClick={() =>
                                    handleSaveItem(
                                      checklistIndex,
                                      itemIndex,
                                      editingText
                                    )
                                  }
                                >
                                  <SaveIcon />
                                </IconButton>
                                <IconButton
                                  sx={{
                                    color:
                                      themeDetails[settings.cardColor]?.font ||
                                      "darkgrey",
                                  }}
                                  onClick={() =>
                                    handleModifyMembers(
                                      checklistIndex,
                                      itemIndex
                                    )
                                  }
                                >
                                  <PersonAddIcon />
                                </IconButton>
                                <IconButton
                                  color="error"
                                  onClick={() =>
                                    handleDeleteItem(checklistIndex, itemIndex)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            ) : (
                              <div
                                style={{ width: "100%" }}
                                onClick={() => {
                                  setRenamingItem({
                                    checklistIndex,
                                    itemIndex,
                                  });
                                  setEditingText(item.text);
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  className="capitalize-first-letter"
                                  style={{
                                    textDecoration: item.completed
                                      ? "line-through"
                                      : "none",
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                >
                                  {item?.text}{" "}
                                  {item?.text?.trim()?.length === 0 && "empty"}
                                </Typography>
                              </div>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <Tooltip
                                title={
                                  <Box>
                                    {item.collaborators &&
                                    item.collaborators.length > 0 ? (
                                      item.collaborators.map(
                                        (collaboratorId, idx) => {
                                          const collaborator =
                                            collaborators.find(
                                              (c) => c.uid === collaboratorId
                                            );
                                          return (
                                            <Typography
                                              key={idx}
                                              variant="body2"
                                            >
                                              {collaborator?.displayName ||
                                                collaborator?.email}
                                            </Typography>
                                          );
                                        }
                                      )
                                    ) : (
                                      <Typography variant="body2">
                                        No collaborators assigned
                                      </Typography>
                                    )}
                                  </Box>
                                }
                                placement="top"
                                arrow
                              >
                                <Stack
                                  direction="row"
                                  spacing={-1}
                                  sx={{ marginLeft: "10px" }}
                                >
                                  {item.collaborators &&
                                    item.collaborators.map(
                                      (collaboratorId, idx) => {
                                        const collaborator = collaborators.find(
                                          (c) => c.uid === collaboratorId
                                        );
                                        return (
                                          <Avatar
                                            key={idx}
                                            src={collaborator?.photoURL}
                                            alt={
                                              collaborator?.displayName ||
                                              collaborator?.email
                                            }
                                            sx={{ width: 30, height: 30 }}
                                            onClick={() =>
                                              handleModifyMembers(
                                                checklistIndex,
                                                itemIndex
                                              )
                                            }
                                          >
                                            {collaborator?.displayName?.[0] ||
                                              collaborator?.email?.[0]}
                                          </Avatar>
                                        );
                                      }
                                    )}
                                </Stack>
                              </Tooltip>
                            </Box>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>

              {addingItemIndex !== checklistIndex ? (
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <SquaredButtonGreen
                    sx={{
                      marginBottom: "10px",
                      width: "100px",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      setAddingItemIndex(checklistIndex);
                    }}
                  >
                    Add
                  </SquaredButtonGreen>
                </div>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ marginBottom: "10px" }}
                >
                  <TeldioTextField
                    placeholderColor={
                      themeDetails[settings.cardColor]?.font || "darkgrey"
                    }
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    placeholder="New item"
                    autoFocus
                    size="small"
                    sx={{ width: "90%" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddChecklistItem(checklist);
                        e.stopPropagation();
                      } else if (e.key === "Escape") {
                        setNewItem("");
                        setAddingItemIndex(null);
                        e.stopPropagation();
                      }
                    }}
                  />
                  <IconButton
                    onClick={() => handleAddChecklistItem(checklist)}
                    color="success"
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => {
                      setNewItem("");
                      setAddingItemIndex(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          );
        })}
      </DragDropContext>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          style: {
            backgroundColor:
              themeDetails[settings.cardColor]?.secondary || "white",
            color: themeDetails[settings.cardColor]?.font || "black",
          },
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: themeDetails[settings.cardColor]?.font || "black" }}
          >
            Are you sure you want to delete this checklist? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteChecklist}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openCollaboratorModal}
        onClose={() => setOpenCollaboratorModal(false)}
        aria-labelledby="collaborator-modal-title"
        aria-describedby="collaborator-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: "5px",
            p: 2,
          }}
        >
          <Typography id="collaborator-modal-title" variant="h6" component="h2">
            Assign Collaborators
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <List>
            {collaborators.map((collaborator) => (
              <ListItemButton
                key={collaborator.uid}
                onClick={() => handleToggleCollaborator(collaborator.uid)}
              >
                <TeldioCheckbox
                  checked={selectedCollaborators.includes(collaborator.uid)}
                  tabIndex={-1}
                  disableRipple
                />
                <Avatar
                  src={collaborator.photoURL}
                  alt={collaborator.displayName || collaborator.email}
                  sx={{ marginRight: "10px" }}
                >
                  {collaborator.displayName?.[0] || collaborator.email?.[0]}
                </Avatar>
                <ListItemText
                  primary={collaborator.displayName || collaborator.email}
                />
              </ListItemButton>
            ))}
          </List>
          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            <SquaredButtonGray onClick={() => setOpenCollaboratorModal(false)}>
              Cancel
            </SquaredButtonGray>
            <SquaredButtonGreen
              onClick={handleSaveCollaborators}
              variant="contained"
            >
              Save
            </SquaredButtonGreen>
          </Stack>
        </Box>
      </Modal>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            setRenamingItem({
              checklistIndex: selectedItem.checklistIndex,
              itemIndex: selectedItem.itemIndex,
            });
            setEditingText(selectedItem.text);
            handleClose();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Checklist;
