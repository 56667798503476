import React, { useState, useEffect, useCallback } from "react";
import {
  ref,
  onValue,
  set,
  push,
  update,
  get,
  off,
  remove,
} from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import Task from "./Task";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Button,
  Popover,
  ListItemIcon,
  ListItemText,
  Divider,
  Snackbar,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DoneIcon from "@mui/icons-material/Done";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import {
  SquaredButtonGreen,
  TeldioTextField,
} from "../../MUI_Components/Components";
import { convertToArray, formatDate } from "../../Helper/utils";

import { CSSTransition } from "react-transition-group";
import "./Boards.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SaveIcon from "@mui/icons-material/Save";
import { useSettings } from "../../Contexts/SettingsContext";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ArrowUpward } from "@mui/icons-material";
import DensityLargeIcon from "@mui/icons-material/DensityLarge";
import DividerCard from "./DividerCard";
import { useThemeContext } from "../../Contexts/ThemeDetailsContext";
const Column = ({
  boardId,
  columnId,
  labels,
  boardOwner,
  collaborators,
  openTask,
  setOpenTask,
  boardTitle,
  showingArchived,
  filteredMembers,
  filteredLabels,
  logAuditForWatchers,
  showingCardCount,
  reverseComments,
  setReverseComments,
  logToColumn,
  ownBoards,
  collaboratedBoards,
  searchBarText,
  activity,
  useGradient,
  heatmapThreshold,
}) => {
  const [column, setColumn] = useState({
    tasks: {},
    taskOrder: [],
  });
  const [addingTask, setAddingTask] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const [newColumnTitle, setNewColumnTitle] = useState("");
  const [numTasks, setNumTasks] = useState();
  const [collapsed, setCollapsed] = useState();
  const [isWatching, setIsWatching] = useState(false);
  const [openCopyLink, setOpenCopyLink] = useState(false);
  const [isLinkCard, setIsLinkCard] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmColumnName, setConfirmColumnName] = useState("");
  const { currentUser } = useAuth();
  const [disableAddCardButton, setDisableAddCardButton] = useState();
  const db = getDB();
  const { settings } = useSettings();
  const [addingLocation, setAddingLocation] = useState("bottom");
  const boardPath =
    boardOwner === "own"
      ? `/uid/${currentUser.uid}/boards/${boardId}`
      : `/uid/${boardOwner}/boards/${boardId}`;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const themeDetails = useThemeContext();
  const [hovered, setHovered] = useState(false); // Track hover state

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (hovered && (event.ctrlKey || event.metaKey)) {
        setCollapsed(false); // Temporarily expand the column
      }
    };

    const handleKeyUp = () => {
      if (hovered) {
        setCollapsed(true); // Collapse the column again
      }
    };

    // Add event listeners for keydown and keyup
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [hovered]);

  // Track hover state
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setCollapsed(true); // Ensure column is collapsed when mouse leaves
  };

  useEffect(() => {
    const watchersRef = ref(db, `${boardPath}/columns/${columnId}/watchers`);
    onValue(watchersRef, (snapshot) => {
      const watchers = snapshot.val() || {};
      setIsWatching(!!watchers[currentUser.uid]);
    });
  }, [columnId, boardPath, db, currentUser.uid]);

  const toggleWatchStatus = async () => {
    let watchersRef = ref(db, `${boardPath}/columns/${columnId}/watchers`);

    if (isWatching) {
      // Remove current user from watchers
      watchersRef = ref(
        db,
        `${boardPath}/columns/${columnId}/watchers/${currentUser.uid}`
      );
      await remove(watchersRef);
    } else {
      // Add current user to watchers
      await update(watchersRef, {
        [currentUser.uid]: true,
      });
    }

    setIsWatching(!isWatching);
  };

  useEffect(() => {
    const boardRef = ref(db, boardPath);
    const listener = onValue(boardRef, (snapshot) => {
      const boardData = snapshot.val();
      if (boardData) {
        if (boardOwner === "own") {
          const collapsedColumns = boardData?.settings?.collapsed
            ? Object.keys(boardData.settings.collapsed)
            : [];
          setCollapsed(collapsedColumns.includes(columnId));
        } else {
          const collapsedColumns = boardData?.collaborators?.[currentUser?.uid]
            ?.collapsed
            ? Object.keys(
                boardData?.collaborators?.[currentUser?.uid]?.collapsed
              )
            : [];
          setCollapsed(collapsedColumns.includes(columnId));
        }
      }
    });

    return () => {
      off(boardRef, listener);
    };
  }, [boardPath, db, boardOwner, currentUser, columnId]);

  useEffect(() => {
    const columnRef = ref(db, `${boardPath}/columns/${columnId}`);
    const unsubscribe = onValue(columnRef, (snapshot) => {
      const columnData = snapshot.val();
      let columnDataParsed = {
        ...columnData,
        tasks: columnData?.tasks || {},
        taskOrder: columnData?.taskOrder || [],
      };
      setNumTasks(
        showingArchived
          ? convertToArray(columnData?.tasks)?.filter((task) => task.title)
              .length || 0
          : convertToArray(columnData?.tasks)?.filter(
              (task) => task.archived !== true && task.title
            ).length || 0
      );
      setColumn(columnDataParsed);
    });

    return () => unsubscribe();
  }, [
    boardId,
    columnId,
    currentUser.uid,
    db,
    boardOwner,
    boardPath,
    showingArchived,
  ]);

  const handleOpenTaskModal = useCallback(
    (taskId) => {
      setOpenTask({ columnId, taskId });
    },
    [columnId, setOpenTask]
  );

  useEffect(() => {
    if (openTask.columnId === columnId && openTask.taskId) {
      handleOpenTaskModal(openTask.taskId);
      setOpenTask({ columnId: null, taskId: null });
    }
  }, [openTask, columnId, setOpenTask, handleOpenTaskModal]);

  const handleAddTask = async () => {
    if (newTaskTitle?.trim() !== "") {
      const newTaskId = push(
        ref(db, `${boardPath}/columns/${columnId}/tasks`)
      ).key;

      let newTaskOrder;
      if (addingLocation === "top") {
        newTaskOrder = [newTaskId, ...(column.taskOrder || [])];
      } else {
        newTaskOrder = column.taskOrder
          ? [...column.taskOrder, newTaskId]
          : [newTaskId];
      }

      await set(
        ref(db, `${boardPath}/columns/${columnId}/tasks/${newTaskId}`),
        {
          title: newTaskTitle,
          description: "",
          author: currentUser.uid,
          linkURL: isLinkCard ? newTaskTitle : "",
        }
      );

      await set(
        ref(db, `${boardPath}/columns/${columnId}/taskOrder`),
        newTaskOrder
      );

      // Log the audit entry
      const auditRef = push(ref(db, `${boardPath}/columns/${columnId}/audit`));
      const newAudit = {
        action: "Column Watcher",
        details: isLinkCard
          ? `New task linked by  ${
              currentUser.displayName || currentUser.email
            }`
          : `New task "${newTaskTitle}" added by ${
              currentUser.displayName || currentUser.email
            }`,
        user: currentUser.uid,
        timestamp: Date.now(),
        processed: false,
        sender: currentUser.displayName || currentUser.email,
        senderUID: currentUser.uid,
      };
      await set(auditRef, newAudit);

      const auditRefLocal = ref(
        db,
        `${boardPath}/columns/${columnId}/tasks/${newTaskId}/audit`
      );
      const newAuditEntry = {
        action: "Card Created",
        details: `${
          currentUser.displayName || currentUser.email
        } created card "${newTaskTitle}" at ${formatDate(new Date())} in ${
          column.title
        }`,
        timestamp: Date.now(),
        sender: currentUser.displayName || currentUser.email,
        senderUID: currentUser.uid,
        relativeDetails: `created this card in ${column.title}`,
      };
      await push(auditRefLocal, newAuditEntry);

      setNewTaskTitle("");
      setAddingTask(false);
      setIsLinkCard(false);

      // Scroll logic
      if (addingLocation === "top") {
        // Scroll to the top of the column
        const columnElement = document.querySelector(`#column-${columnId}`);
        if (columnElement) {
          columnElement.scrollTo({ top: 0, behavior: "smooth" });
        }
      } else {
        // Scroll to the bottom of the column
        const columnElement = document.querySelector(`#column-${columnId}`);
        if (columnElement) {
          columnElement.scrollTo({
            top: columnElement.scrollHeight,
            behavior: "smooth",
          });
        }
      }
    }
  };

  const handleAddDivider = async () => {
    setAnchorEl(null);

    const newTaskId = push(
      ref(db, `${boardPath}/columns/${columnId}/tasks`)
    ).key;

    let newTaskOrder;

    newTaskOrder = [newTaskId, ...(column.taskOrder || [])];

    await set(ref(db, `${boardPath}/columns/${columnId}/tasks/${newTaskId}`), {
      author: currentUser.uid,
      type: "divider",
    });

    await set(
      ref(db, `${boardPath}/columns/${columnId}/taskOrder`),
      newTaskOrder
    );

    // Log the audit entry
    // const auditRef = push(ref(db, `${boardPath}/columns/${columnId}/audit`));
    // const newAudit = {
    //   action: "Column Watcher",
    //   details: `New task "${newTaskTitle}" added by ${
    //     currentUser.displayName || currentUser.email
    //   }`,
    //   user: currentUser.uid,
    //   timestamp: Date.now(),
    //   processed: false,
    //   sender: currentUser.displayName || currentUser.email,
    //   senderUID: currentUser.uid,
    // };
    // await set(auditRef, newAudit);

    // const auditRefLocal = ref(
    //   db,
    //   `${boardPath}/columns/${columnId}/tasks/${newTaskId}/audit`
    // );
    // const newAuditEntry = {
    //   action: "Card Created",
    //   details: `${
    //     currentUser.displayName || currentUser.email
    //   } created card "${newTaskTitle}" at ${formatDate(new Date())}`,
    //   timestamp: Date.now(),
    //   sender: currentUser.displayName || currentUser.email,
    //   senderUID: currentUser.uid,
    //   relativeDetails: `created this card at ${formatDate(new Date())}`,
    // };
    // await push(auditRefLocal, newAuditEntry);

    // setNewTaskTitle("");
    // setAddingTask(false);
    // setIsLinkCard(false);
  };

  const handleRenameColumn = () => {
    if (newColumnTitle?.trim() !== "" && newColumnTitle?.trim().length < 30) {
      set(ref(db, `${boardPath}/columns/${columnId}/title`), newColumnTitle);
      setNewColumnTitle("");
      setRenaming(false);
    }
  };

  const handleToggleCollapsedUser = async () => {
    handleMenuClose();

    try {
      // Fetch current settings
      let settingsRef;

      if (boardOwner === "own") {
        settingsRef = ref(db, boardPath + "/settings");
      } else {
        settingsRef = ref(db, boardPath + "/collaborators/" + currentUser.uid);
      }
      const settingsSnap = await get(settingsRef);
      let settings = settingsSnap.val() || {};

      // Initialize collapsed object if it doesn't exist
      settings.collapsed = settings.collapsed || {};
      // Toggle the columnId in the collapsed object
      if (settings.collapsed[columnId]) {
        delete settings.collapsed[columnId];
      } else {
        settings.collapsed[columnId] = true;
      }

      if (boardOwner === "own") {
        if (Object.keys(settings.collapsed).length === 0) {
          await update(settingsRef, { collapsed: null });
        } else {
          await update(settingsRef, { collapsed: settings.collapsed });
        }
      } else {
        console.log(settings.collapsed);
        if (Object.keys(settings.collapsed).length === 0) {
          await update(
            ref(db, boardPath + "/collaborators/" + currentUser.uid),
            { collapsed: null }
          );
        } else {
          await update(
            ref(db, boardPath + "/collaborators/" + currentUser.uid),
            { collapsed: settings.collapsed }
          );
        }
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleCancelTask = () => {
    setNewTaskTitle("");
    setIsLinkCard(false);
    setAddingTask(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddCardClick = () => {
    setAddingLocation("bottom");
    setAddingTask("top");
    setAnchorEl(null);
  };

  const handleRenameClick = () => {
    setRenaming(true);
    setNewColumnTitle(column?.title);
    setAnchorEl(null);
  };

  const handleRenameCancel = () => {
    setRenaming(false);
    setNewColumnTitle(column.title);
  };

  const handleRenameKeyPress = (event) => {
    if (event.key === "Escape") {
      handleRenameCancel();
    }
    if (event.key === "Enter") {
      handleRenameColumn();
    }
  };

  const handlePasteCard = async () => {
    navigator.clipboard.readText().then((text) => {
      getDataFromDB(text);
    });
  };

  const getDataFromDB = async (path) => {
    console.log(path);
    path = path.split(",");
    if (path.length !== 4) {
      setOpenCopyLink("Invalid Card");
      return;
    } else {
      console.log(path);
      path = `/uid/${path[0]}/boards/${path[1]}/columns/${path[2]}/tasks/${path[3]}`;
    }

    try {
      console.log(path);
      const cardPath = ref(db, path);
      console.log(cardPath);
      const cardData = await get(cardPath);
      let data = cardData.val() || {};
      console.log(data);
      handleCopyTask(data);
    } catch (error) {
      setOpenCopyLink("Failed to copy card");
    }
  };

  const handleCopyTask = async (contents) => {
    handleMenuClose();
    contents.assigned = [];
    contents.labels = [];
    contents.watchers = [];
    contents.audit = [];

    const newTaskId = push(
      ref(db, `${boardPath}/columns/${columnId}/tasks`)
    ).key;

    const newTaskOrder = column.taskOrder
      ? [...column.taskOrder, newTaskId]
      : [newTaskId];

    await set(
      ref(db, `${boardPath}/columns/${columnId}/tasks/${newTaskId}`),
      contents
    );

    await set(
      ref(db, `${boardPath}/columns/${columnId}/taskOrder`),
      newTaskOrder
    );

    // Log the audit entry
    const auditRef = push(ref(db, `${boardPath}/columns/${columnId}/audit`));
    const newAudit = {
      action: "Column Watcher",
      details: `"${contents.title}" copied into ${column.title} by ${
        currentUser.displayName || currentUser.email
      }`,
      user: currentUser.uid,
      timestamp: Date.now(),
      processed: false,
      sender: currentUser.displayName || currentUser.email,
      senderUID: currentUser.uid,
    };
    await set(auditRef, newAudit);

    setNewTaskTitle("");
    setAddingTask(false);
  };

  const handleDeleteColumn = async () => {
    await remove(ref(db, `${boardPath}/columns/${columnId}`));
    setConfirmDelete(false);
    setConfirmColumnName("");
    handleMenuClose();
  };

  if (!column) return <div>Loading...</div>;

  const sortedTasks = column.taskOrder || [];

  return (
    <>
      <Snackbar
        open={openCopyLink}
        autoHideDuration={5000}
        onClose={() => setOpenCopyLink(false)}
        message={openCopyLink}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Column"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the column{" "}
            <strong>{column.title}</strong>? This action will delete all cards
            in this column. To confirm, please type the column name below.
          </DialogContentText>
          <TeldioTextField
            placeholderColor={
              themeDetails[settings.cardColor]?.font || "darkgrey"
            }
            autoFocus
            margin="dense"
            id="columnName"
            label="Column Name"
            type="text"
            fullWidth
            value={confirmColumnName}
            onChange={(e) => setConfirmColumnName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <SquaredButtonGreen
            onClick={handleDeleteColumn}
            disabled={
              confirmColumnName?.trim().toLowerCase() !==
              column?.title?.trim().toLowerCase()
            }
          >
            Delete
          </SquaredButtonGreen>
        </DialogActions>
      </Dialog>
      {!collapsed && (
        <Paper
          sx={{
            margin: "10px 5px",
            backgroundColor:
              themeDetails[settings.cardColor]?.secondary || "#F1F2F4",
            color: themeDetails[settings.cardColor]?.font || "#white",
            padding: "5px 10px 10px 10px",
            width: "300px",
            flexShrink: 0,
            borderRadius: "20px",
            maxHeight: "calc(100vh - 190px)",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {renaming ? (
              <TeldioTextField
                placeholderColor={
                  themeDetails[settings.cardColor]?.font || "darkgrey"
                }
                value={newColumnTitle}
                onChange={(e) => setNewColumnTitle(e.target.value)}
                onKeyDown={handleRenameKeyPress}
                autoFocus
                fullWidth
              />
            ) : (
              <Typography sx={{ fontSize: "13pt", marginRight: "10px" }}>
                {column.title}
              </Typography>
            )}
            {!renaming && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isWatching && (
                    <VisibilityIcon
                      fontSize="small"
                      sx={{ color: "#A0AD39", marginRight: "0px" }}
                    />
                  )}
                  {settings?.showCount && (
                    <CSSTransition
                      in={numTasks > 0}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <div
                        style={{
                          width: "auto",
                          height: "auto",
                          padding: "0px 5px",
                          borderRadius: "5px",
                          textAlign: "center",
                          backgroundColor: "#E09952",
                          fontWeight: "bold",
                          color: "white",
                          marginLeft: "10px",
                        }}
                      >
                        {numTasks}
                      </div>
                    </CSSTransition>
                  )}
                  <IconButton onClick={handleMenuClick}>
                    <MoreVertIcon
                      sx={{
                        color:
                          themeDetails[settings.cardColor]?.font || "#white",
                      }}
                    />
                  </IconButton>
                </div>

                <Popover
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 0px 10px 0px",
                      width: "300px",
                      backgroundColor:
                        themeDetails[settings.cardColor]?.primary || "white",
                      color:
                        themeDetails[settings.cardColor]?.font || "darkgrey",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "13px",
                        }}
                      >
                        Column actions
                      </Typography>
                      <IconButton
                        sx={{ position: "absolute", right: 5 }}
                        onClick={handleMenuClose}
                      >
                        <CloseIcon
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </IconButton>
                    </div>
                    <MenuItem
                      startIcon={<AddIcon />}
                      onClick={handleAddCardClick}
                    >
                      <ListItemIcon>
                        <AddIcon
                          fontSize="small"
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Add a Card</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleAddDivider}>
                      <ListItemIcon>
                        <DensityLargeIcon
                          fontSize="small"
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Add a Divider</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleRenameClick}>
                      <ListItemIcon>
                        <DriveFileRenameOutlineIcon
                          fontSize="small"
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Rename Column</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleToggleCollapsedUser}>
                      <ListItemIcon>
                        <CloseFullscreenIcon
                          fontSize="small"
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Collapse Column</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handlePasteCard}>
                      <ListItemIcon>
                        <ContentPasteIcon
                          fontSize="small"
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Paste Card from Clipboard</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => setConfirmDelete(true)}>
                      <ListItemIcon>
                        <ClearIcon
                          fontSize="small"
                          sx={{
                            color:
                              themeDetails[settings.cardColor]?.font ||
                              "darkgrey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Delete Column</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={toggleWatchStatus}>
                      <ListItemIcon>
                        {isWatching ? (
                          <VisibilityOffIcon
                            fontSize="small"
                            sx={{
                              color:
                                themeDetails[settings.cardColor]?.font ||
                                "darkgrey",
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            fontSize="small"
                            sx={{
                              color:
                                themeDetails[settings.cardColor]?.font ||
                                "darkgrey",
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        {isWatching ? "Stop Watching" : "Watch Column"}
                      </ListItemText>
                    </MenuItem>
                  </div>
                </Popover>
              </>
            )}
            {renaming && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleRenameColumn}>
                  <SaveIcon
                    sx={{
                      color:
                        themeDetails[settings.cardColor]?.font || "darkgrey",
                    }}
                  />
                </IconButton>
                <IconButton onClick={handleRenameCancel}>
                  <ClearIcon
                    sx={{
                      color:
                        themeDetails[settings.cardColor]?.font || "darkgrey",
                    }}
                  />
                </IconButton>{" "}
              </div>
            )}
          </Box>

          {addingTask === "top" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <TeldioTextField
                  placeholderColor={
                    themeDetails[settings.cardColor]?.font || "darkgrey"
                  }
                  placeholder={isLinkCard ? "Task URL" : "Task Title"}
                  value={newTaskTitle}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (isLinkCard) {
                      console.log(inputValue.split(",").length);
                      console.log(inputValue);
                      if (inputValue.split(",").length === 4) {
                        setDisableAddCardButton(false);
                      } else {
                        setDisableAddCardButton(true);
                      }
                    }
                    setNewTaskTitle(inputValue);
                  }}
                  fullWidth
                  autoFocus
                  variant="outlined"
                  size="medium"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddTask();
                      e.stopPropagation();
                    } else if (e.key === "Escape") {
                      handleCancelTask();
                      e.stopPropagation();
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    sx={{ padding: "2px" }}
                    onClick={() => setAddingLocation("top")}
                  >
                    <ArrowUpwardIcon
                      sx={{
                        fontSize: "20px",
                        color: themeDetails[settings.cardColor]?.font,
                        opacity: addingLocation === "top" ? 1 : 0.2,
                      }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{ padding: "2px" }}
                    onClick={() => setAddingLocation("bottom")}
                  >
                    <ArrowDownwardIcon
                      sx={{
                        fontSize: "20px",
                        color: themeDetails[settings.cardColor]?.font,
                        opacity: addingLocation === "bottom" ? 1 : 0.2,
                      }}
                    />
                  </IconButton>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <IconButton color="error" onClick={handleCancelTask}>
                  <ClearIcon />
                </IconButton>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => {
                      if (isLinkCard) {
                        setDisableAddCardButton(false);
                      } else {
                        setDisableAddCardButton(true);
                      }
                      setIsLinkCard(!isLinkCard);
                    }}
                  >
                    {isLinkCard ? <LinkIcon /> : <LinkOffIcon />}
                  </IconButton>

                  <SquaredButtonGreen
                    startIcon={<DoneIcon />}
                    onClick={handleAddTask}
                    disabled={disableAddCardButton}
                  >
                    Add Card
                  </SquaredButtonGreen>
                </div>
              </div>
            </Box>
          )}
          <Droppable droppableId={columnId} type="TASK">
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  flex: 1,
                  minHeight: "30px",
                }}
              >
                {sortedTasks.map((taskId, index) => {
                  const taskData = column.tasks[taskId];
                  const linkTaskArray = taskData?.linkURL
                    ? taskData.linkURL.split(",")
                    : [];
                  const isLinked = linkTaskArray.length > 0;
                  const taskOwnerUID = taskData?.linkURL
                    ? linkTaskArray[0] === currentUser.uid
                      ? "own"
                      : linkTaskArray[0]
                    : boardOwner;
                  const taskBoardID = taskData?.linkURL
                    ? linkTaskArray[1]
                    : boardId;
                  const taskColumnID = taskData?.linkURL
                    ? linkTaskArray[2]
                    : columnId;
                  const taskTaskID = taskData?.linkURL
                    ? linkTaskArray[3]
                    : taskId;

                  const realTaskPath =
                    linkTaskArray.length > 0
                      ? taskOwnerUID === "own"
                        ? `/uid/${currentUser.uid}/boards/${boardId}/columns/${columnId}/tasks/${taskId}`
                        : `/uid/${taskOwnerUID}/boards/${boardId}/columns/${columnId}/tasks/${taskId}`
                      : false;

                  const taskPath =
                    taskOwnerUID === "own"
                      ? `/uid/${currentUser.uid}/boards/${taskBoardID}/columns/${taskColumnID}/tasks/${taskTaskID}`
                      : `/uid/${taskOwnerUID}/boards/${taskBoardID}/columns/${taskColumnID}/tasks/${taskTaskID}`;

                  return (
                    <Draggable
                      key={taskId + columnId}
                      draggableId={taskId}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ margin: "10px 0" }}
                        >
                          {taskData?.type !== "divider" && (
                            <Task
                              key={taskId + "task"}
                              collaborators={collaborators}
                              boardId={boardId}
                              columnId={columnId}
                              taskId={taskId}
                              columnName={column.title}
                              labels={labels}
                              boardOwner={boardOwner}
                              openTask={openTask}
                              handleOpenTaskModal={handleOpenTaskModal}
                              boardTitle={boardTitle}
                              showingArchived={showingArchived}
                              filteredMembers={filteredMembers}
                              filteredLabels={filteredLabels}
                              logToColumn={logToColumn}
                              reverseComments={reverseComments}
                              setReverseComments={setReverseComments}
                              taskPath={taskPath}
                              isLinked={isLinked}
                              boardPath={boardPath}
                              realTaskPath={realTaskPath}
                              ownBoards={ownBoards}
                              collaboratedBoards={collaboratedBoards}
                              searchBarText={searchBarText}
                              activity={activity}
                              useGradient={
                                useGradient !== undefined ? useGradient : true
                              }
                              heatmapThreshold={heatmapThreshold}
                            />
                          )}
                          {taskData?.type === "divider" && (
                            <DividerCard
                              key={taskId + "task"}
                              collaborators={collaborators}
                              boardId={boardId}
                              columnId={columnId}
                              taskId={taskId}
                              columnName={column.title}
                              labels={labels}
                              boardOwner={boardOwner}
                              openTask={openTask}
                              handleOpenTaskModal={handleOpenTaskModal}
                              boardTitle={boardTitle}
                              showingArchived={showingArchived}
                              filteredMembers={filteredMembers}
                              filteredLabels={filteredLabels}
                              logToColumn={logToColumn}
                              reverseComments={reverseComments}
                              setReverseComments={setReverseComments}
                              taskPath={taskPath}
                              isLinked={isLinked}
                              boardPath={boardPath}
                              realTaskPath={realTaskPath}
                              ownBoards={ownBoards}
                              collaboratedBoards={collaboratedBoards}
                              searchBarText={searchBarText}
                              activity={activity}
                            />
                          )}
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
          {addingTask === "bottom" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                flexDirection: "column",
                marginTop: "15px",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <TeldioTextField
                  placeholderColor={
                    themeDetails[settings.cardColor]?.font || "darkgrey"
                  }
                  placeholder={isLinkCard ? "Task URL" : "Task Title"}
                  value={newTaskTitle}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (isLinkCard) {
                      console.log(inputValue.split(",").length);
                      console.log(inputValue);
                      if (inputValue.split(",").length === 4) {
                        setDisableAddCardButton(false);
                      } else {
                        setDisableAddCardButton(true);
                      }
                    }
                    setNewTaskTitle(inputValue);
                  }}
                  fullWidth
                  autoFocus
                  variant="outlined"
                  size="medium"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddTask();
                      e.stopPropagation();
                    } else if (e.key === "Escape") {
                      handleCancelTask();
                      e.stopPropagation();
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    sx={{ padding: "2px" }}
                    onClick={() => setAddingLocation("top")}
                  >
                    <ArrowUpwardIcon
                      sx={{
                        fontSize: "20px",
                        color: themeDetails[settings.cardColor]?.font,
                        opacity: addingLocation === "top" ? 1 : 0.2,
                      }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{ padding: "2px" }}
                    onClick={() => setAddingLocation("bottom")}
                  >
                    <ArrowDownwardIcon
                      sx={{
                        fontSize: "20px",
                        color: themeDetails[settings.cardColor]?.font,
                        opacity: addingLocation === "bottom" ? 1 : 0.2,
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "10px 0px 0px 0px",
                }}
              >
                <IconButton color="error" onClick={handleCancelTask}>
                  <ClearIcon />
                </IconButton>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => {
                      if (isLinkCard) {
                        setDisableAddCardButton(false);
                      } else {
                        setDisableAddCardButton(true);
                      }
                      setIsLinkCard(!isLinkCard);
                    }}
                  >
                    {isLinkCard ? <LinkIcon /> : <LinkOffIcon />}
                  </IconButton>

                  <SquaredButtonGreen
                    startIcon={<DoneIcon />}
                    onClick={handleAddTask}
                    disabled={disableAddCardButton}
                  >
                    Add Card
                  </SquaredButtonGreen>
                </div>
              </div>
            </Box>
          )}
          {!addingTask && (
            <Button
              startIcon={<AddIcon />}
              fullWidth
              variant="text"
              onClick={() => {
                setAddingLocation("bottom");
                setAddingTask("bottom");
              }}
              sx={{
                marginBottom: "5px",
                alignContent: "left",
                textTransform: "none",
                alignItems: "left",
                color: "#A0AD39",
              }}
            >
              Add a card
            </Button>
          )}
        </Paper>
      )}
      {collapsed && (
        <Paper
          sx={{
            margin: "10px 5px",
            backgroundColor: "#F1F2F4",
            backgroundColor:
              themeDetails[settings.cardColor]?.secondary || "#F1F2F4",
            color: themeDetails[settings.cardColor]?.font || "#white",
            padding: "5px 10px 10px 10px",
            width: collapsed ? "40px" : "300px", // Adjust width based on collapse state
            flexShrink: 0,
            borderRadius: "20px",
            maxHeight: "calc(100vh - 190px)",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            transition: "width 0.3s", // Smooth transition for expanding/collapsing
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <IconButton onClick={handleToggleCollapsedUser}>
            <ArrowForwardIosIcon
              sx={{ color: themeDetails[settings.cardColor]?.font || "#white" }}
            />
          </IconButton>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ textOrientation: "sideways", writingMode: "vertical-lr" }}
            >
              {column.title}
            </Typography>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            {isWatching && (
              <VisibilityIcon fontSize="small" sx={{ color: "#A0AD39" }} />
            )}
          </div>
          <CSSTransition
            in={numTasks > 0}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div
              style={{
                width: "auto",
                height: "auto",
                padding: "0px 5px",
                borderRadius: "5px",
                textAlign: "center",
                backgroundColor: "#E09952",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {numTasks}
            </div>
          </CSSTransition>
        </Paper>
      )}
    </>
  );
};

export default Column;
